import { useLocales } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { format, parseISO } from 'date-fns'
import { ReactNode } from 'react'

type DatePickerWrapperProps = {
  label: string
  children: ReactNode
  isOpenDatePicker: boolean
  onClick: () => void
  dateSelected: string
}

const DatePickerWrapper = ({ label, children, isOpenDatePicker, onClick, dateSelected }: DatePickerWrapperProps) => {
  const { currentLang } = useLocales()
  return (
    <Box height={66}>
      <Box
        component="label"
        htmlFor="start-date"
        fontSize={12}
        fontWeight={600}
        color={isOpenDatePicker ? 'primary.dark' : 'grey.700'}
        textTransform="uppercase"
      >
        {label}
      </Box>
      <Box
        id="start-date"
        onClick={onClick}
        sx={{
          bgcolor: 'white',
          border: '1px solid',
          borderColor: isOpenDatePicker ? 'primary.dark' : 'grey.400',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          p: 1,
          color: 'grey.800',
          fontSize: 14,
          fontWeight: 500,
          cursor: 'pointer',
          ':hover': { borderColor: 'primary.dark' },
        }}
      >
        {dateSelected ? format(parseISO(dateSelected), 'd MMM yyyy', { locale: currentLang.adapterLocale }) : 'Select Date'}
      </Box>
      {children}
    </Box>
  )
}

export default DatePickerWrapper
