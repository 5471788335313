import { styled } from '@mui/material/styles'
import SimpleBar from 'simplebar-react'

const SimpleBarStyled = styled(SimpleBar)(() => ({
  maxHeight: '100%',
  '.simplebar-scrollbar::before': { backgroundColor: 'grey' },
  '& .simplebar-track.simplebar-vertical': { width: 10 },
}))

export default SimpleBarStyled
