import SimpleBarStyled from '@components/containers/SimpleBarStyled'
import Iconify from '@components/data-display/Iconify'
import DrawerHead from '@components/drawer/DrawerHead'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useVineyardStore from '@stores/vineyard'
import { BlockDto } from '@typings/dtos/block'
import { formatISOWithCustomHours } from '@utils/utility-fns'
import { format, parseISO } from 'date-fns'

type ScanTimelineDrawerProps = {
  open: boolean
  onClose: VoidFunction
}

const ScanTimelineDrawer = ({ open, onClose }: ScanTimelineDrawerProps) => {
  const { t } = useTranslate()
  const [blocksGroupByScanDate, blocksAsMap, setSelectedBlocksLocal, setStartDate, setEndDate] = useVineyardStore((s) => [
    s.blocksGroupByScanDate,
    s.blocksAsMap,
    s.setSelectedBlocksLocal,
    s.setStartDateLocal,
    s.setEndDateLocal,
  ])

  /* Find all the blocks with these blockIds and add it to selectedBlocks */
  const handleAddBlocksToFilters = (blocksIds: number[], date: string) => {
    const blocksToAdd = blocksIds.map((blockId) => blocksAsMap.get(blockId) as BlockDto)
    setSelectedBlocksLocal(blocksToAdd)
    setStartDate(formatISOWithCustomHours(new Date(date)))
    setEndDate(formatISOWithCustomHours(new Date(date), { h: 23, m: 59, s: 59, ms: 999 }))
    onClose()
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{ sx: { width: 440, overflow: 'hidden' } }}
      slotProps={{ backdrop: { invisible: true } }}
      keepMounted
    >
      <DrawerHead label={t('scan_timeline')} onClose={onClose} icon="mdi:timeline-check" width={28} />
      <SimpleBarStyled
        sx={{
          height: 1,
          px: 3,
          pt: 2,
          pb: 10,
          '& .simplebar-content': { display: 'flex', flexDirection: 'column' },
        }}
      >
        {blocksGroupByScanDate
          .sort((a, b) => new Date(b.scanDate).getTime() - new Date(a.scanDate).getTime())
          .map((group, index) => (
            <Box key={index} display="flex" gap={2}>
              <Box
                width={2}
                minHeight={60}
                position="relative"
                display="flex"
                sx={{ background: 'linear-gradient(#64C043, rgba(100, 192, 67, 0.1))' }}
              >
                <Box sx={{ position: 'absolute', top: 0, left: -11, bgcolor: 'white', height: 20 }}>
                  <Iconify icon="ri:time-fill" color="primary.main" width={24} />
                </Box>
              </Box>
              <Box width="100%" display="grid" gap={1}>
                <Box display="flex" alignItems="center" mt={-0.3}>
                  <Typography fontSize={16} lineHeight={1.6} noWrap pl={1} color="primary.dark">
                    {format(parseISO(group.scanDate), 'd MMM yyyy')}
                  </Typography>
                  <IconButton
                    title="Add to Filters"
                    size="small"
                    sx={{ ml: 'auto' }}
                    onClick={() => handleAddBlocksToFilters(group.blockIds, group.scanDate)}
                  >
                    <Iconify icon="mingcute:filter-3-fill" />
                  </IconButton>
                </Box>
                <Box display="flex" gap={1} flexWrap="wrap" pb={4}>
                  {group.blockIds.map((block) => (
                    <Box
                      key={block}
                      bgcolor="grey.200"
                      px={1.5}
                      py={0.3}
                      fontSize={13}
                      fontWeight={500}
                      borderRadius={1}
                      color="grey.800"
                    >
                      {blocksAsMap.get(block)?.name}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
      </SimpleBarStyled>
    </Drawer>
  )
}

export default ScanTimelineDrawer
