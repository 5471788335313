import Iconify from '@components/data-display/Iconify'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import { NavLinkItem } from '@typings/component'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MenuBase from './MenuBase'

type NavbarMenuProps = {
  id: string
  label: string
  baseSlug: string
  links: NavLinkItem[]
  menuArrowPositionRight?: number
}

const NavbarMenu = ({ id, label, baseSlug, links, menuArrowPositionRight = 66 }: NavbarMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const location = useLocation()
  const isBaseSlugMatch = location.pathname.startsWith(`/${baseSlug}`)
  const isLinkPathMatch = (link: NavLinkItem) => location.pathname.startsWith(`/${baseSlug}${link.path}`)

  const navigate = useNavigate()
  const { t } = useTranslate()

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Box
          role="button"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-controls={open ? id : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            bgcolor: isBaseSlugMatch ? 'white' : alpha('#000', 0.1),
            color: isBaseSlugMatch ? 'primary.dark' : 'white',
            fontWeight: 500,
            fontSize: 13,
            px: 2,
            py: 0.5,
            borderRadius: 4,
            cursor: 'pointer',
            ':hover': { boxShadow: 1 },
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            userSelect: 'none',
          }}
        >
          {label}
        </Box>
      </Box>
      <MenuBase
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        menuArrowPositionRight={menuArrowPositionRight}
        aria-hidden={!open}
      >
        {links.map((link) => (
          <MenuItem
            key={link.path}
            sx={{
              cursor: 'pointer',
              bgcolor: isLinkPathMatch(link) ? 'primary.main' : 'transparent',
              color: isLinkPathMatch(link) ? 'white' : 'grey.700',
              ':hover': {
                bgcolor: isLinkPathMatch(link) ? 'primary.light' : 'grey.200',
                color: isLinkPathMatch(link) ? 'white' : 'grey.700',
              },
            }}
            onClick={() => {
              setAnchorEl(null)
              navigate(baseSlug + link.path)
            }}
          >
            <ListItemIcon sx={{ color: isLinkPathMatch(link) ? 'white' : 'grey.700' }}>
              <Iconify icon={link.icon} width={24} />
            </ListItemIcon>
            {t(link.name)}
          </MenuItem>
        ))}
      </MenuBase>
    </>
  )
}

export default NavbarMenu
