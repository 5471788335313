import useAuthStore from '@stores/auth'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { defaultLang } from './config-lang'
import translationFr from './langs/fr.json'
import translationUk from './langs/uk.json'

const lng = useAuthStore.getState().userSettings?.language || defaultLang.value

i18n.use(initReactI18next).init({
  lng,
  fallbackLng: lng,
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: { escapeValue: false },
  resources: {
    uk: { translations: translationUk },
    us: { translations: translationUk },
    fr: { translations: translationFr },
  },
})

export default i18n
