import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import BlockNameCustomChip from './BlockNameCustomChip'

type HighlightInfoProps = {
  value: string | number
  label: string
  valueFontSize?: number
  valueFontWeight?: number
  color?: string
  blockNames?: string[]
  labelTooltip?: string
}

export const HighlightInfo = ({
  value,
  label,
  valueFontSize = 22,
  valueFontWeight = 500,
  color = 'primary.main',
  blockNames,
  labelTooltip,
}: HighlightInfoProps) => {
  const { t } = useTranslate()

  return (
    <Box sx={{ cursor: 'default' }}>
      <Box display="flex" gap={0.6} alignItems="end">
        <Typography
          fontSize={valueFontSize}
          fontWeight={valueFontWeight}
          color="info.dark"
          lineHeight={1}
          sx={{ color: color, display: 'flex', alignItems: 'end', gap: 0.4 }}
        >
          {value}
        </Typography>
        {blockNames ? (
          <Box minWidth={0} mb={0.2}>
            {blockNames.length > 1 ? (
              <Box display="flex" gap={0.6}>
                <BlockNameCustomChip value={blockNames[0] || 'Block'} />
                <Tooltip arrow title={blockNames.filter((_, i) => i !== 0).join(', ')}>
                  <BlockNameCustomChip value={`+${blockNames.length - 1}`} textOverflow="clip" pr={1} />
                </Tooltip>
              </Box>
            ) : (
              <BlockNameCustomChip value={blockNames[0] || 'Block'} />
            )}
          </Box>
        ) : null}
      </Box>
      {labelTooltip ? (
        <Tooltip arrow title={t(labelTooltip)}>
          <Typography fontSize={13} fontWeight={500} color="#64748B" lineHeight={1.2} width="fit-content">
            {t(label)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography fontSize={13} fontWeight={500} color="#64748B" lineHeight={1.2} width="fit-content">
          {t(label)}
        </Typography>
      )}
    </Box>
  )
}

export default HighlightInfo
