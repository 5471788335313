import Iconify from '@components/data-display/Iconify'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { ButtonProps } from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { alpha } from '@mui/material/styles'
import {
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import useVineyardStore from '@stores/vineyard'
import { ReactNode } from 'react'
import ReportExportMenuItem from './ReportExportMenuItem'

type CustomGridToolbarProps = {
  fileName: string
  rowCount: number
  dataGridStats?: 'standard' | 'expert'
  onClickDataGridStats?: VoidFunction
  downloadPdf?: () => void
  extensionComponent?: ReactNode
}

const CustomGridToolbar = ({
  fileName,
  rowCount,
  dataGridStats,
  onClickDataGridStats,
  downloadPdf,
  extensionComponent,
}: CustomGridToolbarProps) => {
  const { t } = useTranslate()
  const [activePruningStyle] = useVineyardStore((s) => [s.activePruningStyle])

  const props: Partial<ButtonProps> = { sx: { color: 'primary.dark' } }

  return (
    <GridToolbarContainer>
      {extensionComponent && extensionComponent}
      <GridToolbarColumnsButton slotProps={{ button: props }} />
      <GridToolbarFilterButton slotProps={{ button: props }} />
      <GridToolbarDensitySelector slotProps={{ button: props }} />
      <GridToolbarExportContainer slotProps={{ button: props }}>
        <GridCsvExportMenuItem options={{ fileName: fileName }} />
        {downloadPdf && activePruningStyle === 'Cane' && <ReportExportMenuItem downloadPdf={downloadPdf} />}
      </GridToolbarExportContainer>
      {dataGridStats && (
        <Tooltip title={t('msg_expert_stats')} enterDelay={600}>
          <Box
            component="span"
            role="button"
            onClick={onClickDataGridStats}
            display="flex"
            alignItems="center"
            gap={0.6}
            fontWeight={600}
            fontSize={13}
            px={0.6}
            color="primary.dark"
            sx={{
              cursor: 'pointer',
              ':hover': { bgcolor: alpha('#F0FDF4', 0.5) },
              userSelect: 'none',
            }}
          >
            <Iconify icon={dataGridStats === 'expert' ? 'ic:baseline-grid-3x3' : 'ic:baseline-grid-4x4'} width={19} />
            {dataGridStats === 'expert' ? t('standard_stats') : t('expert_stats')}
          </Box>
        </Tooltip>
      )}

      {rowCount ? (
        <Box ml="auto" mr={1} bgcolor="grey.600" px={1} color="white" fontSize={12} fontWeight={500} borderRadius={0.5}>
          {t('total_rows')}: {rowCount}
        </Box>
      ) : null}
    </GridToolbarContainer>
  )
}

export default CustomGridToolbar
