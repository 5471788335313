import { localStorageGetItem } from '@utils/utility-fns'
import axios, { AxiosRequestConfig } from 'axios'

/* -----------------------------------------------------------------------------------
CROPSY API INSTANCE
 ----------------------------------------------------------------------------------- */
const cropsyInstance = axios.create({ baseURL: import.meta.env.VITE_CROPSY_BASE_URL })

cropsyInstance.interceptors.request.use(
  (config) => {
    const user = localStorageGetItem('user')
    if (user) config.headers['Authorization'] = 'Bearer ' + JSON.parse(user).token
    return config
  },
  (error) => {
    localStorage.removeItem('user')
    return Promise.reject(error)
  }
)

export const cropsy = {
  get: (url: string, config?: AxiosRequestConfig) => cropsyInstance.get(url, config).then((res) => res.data),
  post: (url: string, body: unknown, config?: AxiosRequestConfig) =>
    cropsyInstance.post(url, body, config).then((res) => res.data),
  put: cropsyInstance.put,
  delete: cropsyInstance.delete,
}

/* -----------------------------------------------------------------------------------
IMGMAN API INSTANCE
 ----------------------------------------------------------------------------------- */
const imgmanInstance = axios.create({ baseURL: import.meta.env.VITE_IMGMAN_BASE_URL })

imgmanInstance.interceptors.request.use(
  (config) => {
    const user = localStorageGetItem('user')
    if (user) config.headers['Authorization'] = 'Bearer ' + JSON.parse(user).token
    return config
  },
  (error) => {
    localStorage.removeItem('user')
    return Promise.reject(error)
  }
)

export const imgman = {
  get: (url: string, config?: AxiosRequestConfig) => imgmanInstance.get(url, config).then((res) => res.data),
  post: imgmanInstance.post,
  put: imgmanInstance.put,
  delete: imgmanInstance.delete,
}
