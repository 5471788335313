import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import LabelClass from '@arcgis/core/layers/support/LabelClass'
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer'
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer'
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol'
import TextSymbol from '@arcgis/core/symbols/TextSymbol'
import { alpha } from '@mui/material/styles'

export const blockLabelClass = new LabelClass({
  labelExpressionInfo: { expression: '$feature.name' },
  symbol: new TextSymbol({
    color: 'black',
    haloColor: 'white',
    haloSize: '1px',
    font: { size: 10, weight: 'bold', family: 'Ubuntu Mono' },
  }),
})

export const blockLabelWithDateClass = new LabelClass({
  labelExpressionInfo: { expression: '$feature.name + TextFormatting.NewLine + $feature.scanDay' },
  symbol: new TextSymbol({
    color: 'black',
    haloColor: 'white',
    haloSize: '1px',
    font: { size: 10, weight: 'bold', family: 'Ubuntu Mono' },
  }),
})

export const blockUniqueValueRenderer = new UniqueValueRenderer({
  field: 'scannedDaysAgo',
  field2: 'isSelected',
  fieldDelimiter: '; ',
  uniqueValueInfos: [
    {
      value: 'LessThan16; false',
      symbol: new SimpleFillSymbol({ color: alpha('#FBBF24', 0.8), outline: { color: 'white', width: 2 } }),
    },
    {
      value: 'MoreThan15; false',
      symbol: new SimpleFillSymbol({ color: alpha('#0C4A6E', 0.8), outline: { color: 'white', width: 2 } }),
    },
    {
      value: 'NeverScanned; false',
      symbol: new SimpleFillSymbol({ color: 'transparent', outline: { color: 'white', width: 2 } }),
    },
    {
      value: 'LessThan16; true',
      symbol: new SimpleFillSymbol({ color: 'transparent', outline: { color: 'white', width: 2 } }),
    },
    {
      value: 'MoreThan15; true',
      symbol: new SimpleFillSymbol({ color: 'transparent', outline: { color: 'white', width: 2 } }),
    },
    {
      value: 'NeverScanned; true',
      symbol: new SimpleFillSymbol({ color: 'transparent', outline: { color: 'white', width: 2 } }),
    },
  ],
})

export const blockSimpleRenderer = new SimpleRenderer({
  symbol: new SimpleFillSymbol({ color: [0, 0, 0, 0], outline: { color: 'white', width: 2 } }),
})

export const blockLayer = new FeatureLayer({
  id: 'block-layer',
  title: 'Block Layer',
  source: [],
  fields: [
    { name: 'id', type: 'integer' },
    { name: 'name', type: 'string' },
    { name: 'vineyardId', type: 'integer' },
    { name: 'scannedDaysAgo', type: 'string' },
    { name: 'scanDay', type: 'string' },
    { name: 'isSelected', type: 'string' },
  ],
  objectIdField: 'objectId',
  outFields: ['id', 'name', 'vineyardId', 'scannedDaysAgo'],
  geometryType: 'polygon',
  spatialReference: SpatialReference.WGS84,
  renderer: blockUniqueValueRenderer,
  hasZ: true,
  labelingInfo: [blockLabelWithDateClass],
})

export const blockHighlightLayer = new FeatureLayer({
  id: 'block-highlight-layer',
  title: 'Block Highlight Layer',
  source: [],
  fields: [
    { name: 'id', type: 'integer' },
    { name: 'name', type: 'string' },
  ],
  objectIdField: 'objectId',
  outFields: ['id', 'name'],
  geometryType: 'polygon',
  spatialReference: SpatialReference.WGS84,
  renderer: new SimpleRenderer({
    symbol: new SimpleFillSymbol({
      style: 'none',
      outline: { color: '#FBFB86', width: 3 },
    }),
  }),
  hasZ: true,
})
