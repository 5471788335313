import Box, { BoxProps } from '@mui/material/Box'
import { forwardRef } from 'react'

type BlockNameCustomChipProps = {
  value: string | number
} & BoxProps

const BlockNameCustomChip = forwardRef<HTMLDivElement, BlockNameCustomChipProps>(({ value, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      px={0.7}
      fontSize={12}
      fontWeight={500}
      borderRadius={0.8}
      color="white"
      bgcolor="#64748B"
      lineHeight={1}
      py={0.3}
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      sx={{ cursor: 'default' }}
      {...props}
    >
      {value}
    </Box>
  )
})

export default BlockNameCustomChip
