import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { ColorPost } from '@typings/common'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { LandCane_PostsSortOptions } from '@utils/options'
import { createPostsDataset } from '@utils/utility-fns'
import { TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import i18next from 'i18next'
import { useCallback, useEffect, useState } from 'react'

type PostsStackedBarsChartProps = {
  data: PruningRowStatsAggregated[]
  color: ColorPost
}

const PostsStackedBarsChart = ({ data, color }: PostsStackedBarsChartProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [postsDataset, setPostsDataset] = useState<ReturnType<typeof createPostsDataset>>({
    id: 'land-posts-dataset',
    dimensions: ['Block Code', 'wooden', 'metal', 'missing', 'unknown'],
    source: [],
  })

  const { t } = useTranslate()

  const setStates = useCallback(() => {
    setPostsDataset(createPostsDataset(data))
  }, [data])

  useEffect(() => {
    setStates()
  }, [data, setStates])

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setPostsDataset((prev) => {
        return {
          ...prev,
          source: prev.source.sort((a, b) => {
            return activeSort === 'Block Name-asc'
              ? b['Block Code'].localeCompare(a['Block Code'])
              : a['Block Code'].localeCompare(b['Block Code'])
          }),
        }
      })
    }
    if (activeSort.startsWith('Block Name')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setPostsDataset((prev) => {
        //text before the first hyphen
        const sortKey = activeSort.split('-')[0] as keyof ReturnType<typeof createPostsDataset>['source'][0]
        return {
          ...prev,
          source: prev.source.sort((a, b) => {
            const valueA = a[sortKey] as number
            const valueB = b[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        }
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort, data])

  return (
    <Box pb={4}>
      <SummaryTileWrapper
        label="posts"
        width="100%"
        height="100%"
        tooltipMsg="land_page.summary.cane.tooltip_posts_per_block_barchart"
        headerComponent={
          postsDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-posts-stats"
              menuItems={LandCane_PostsSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={postsDataset}
            legend={{ show: true, formatter: (name) => t(name) }}
            color={[color.wood, color.metal, color.missing, color.unknown]}
            hideLabelValueBelow={0.1}
            formatLabelToPct={false}
            tooltipFormatter={(p) => tooltipFormatter(p, color)}
            yAxisCategoryLabelFormatter={(value) => {
              const blockName = value.split('>')[0] || value
              return blockName
            }}
          />
        </Box>
      </SummaryTileWrapper>
    </Box>
  )
}

export default PostsStackedBarsChart

const tooltipFormatter = (params: TopLevelFormatterParams, color: ColorPost) => {
  const { t } = i18next
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value } = params[0]
  const blockName = name.split('>')[0] || name

  let result = `<div style="margin-bottom: 6px; width: 200px">
                      <div style="text-align: left; font-weight: 600; font-size: 14px;">${blockName}</div>
                </div>`

  const posts = [
    { key: 'wooden', value: value['wooden'], bgcolor: color.wood, text: 'white' },
    { key: 'metal', value: value['metal'], bgcolor: color.metal, text: 'white' },
    { key: 'missing', value: value['missing'], bgcolor: color.missing, text: 'white' },
    { key: 'unknown', value: value['unknown'], bgcolor: color.unknown, text: 'white' },
  ]

  posts.forEach((post) => {
    if (post.value !== 0) {
      result += `<div style="display: flex; color: ${post.text}; justify-content: space-between; gap: 2; background-color:${post.bgcolor}">
            <b style="width: 60px; padding:6px 0 6px 8px;">${t(post.key)}:</b>
            <b style="padding:6px 8px 6px 8px">${post.value}</b>
          </div>`
    }
  })

  return result
}
