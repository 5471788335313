import HelmetWrapper from '@components/containers/HelmetWrapper'
import Layout from '@components/layout'
import { useHandleJwtTokenExpiry } from '@hooks/useHandleJwtTokenExpiry'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Auth from '@pages/Auth'
import Playground from '@pages/Playground'
import SettingsPage from '@pages/SettingsPage'
import HealthReportingPage from '@pages/health/HealthReportingPage'
import LandReportingPage from '@pages/land/LandReportingPage'
import LandSummaryPage from '@pages/land/LandSummaryPage'
import PruningReportingPage from '@pages/pruning/PruningReportingPage'
import PruningSummaryPage from '@pages/pruning/PruningSummaryPage'
import YieldReportingPage from '@pages/yield/YieldReportingPage'
import useAuthStore from '@stores/auth'
import { getLandingPage } from '@utils/utility-fns'
import { Suspense, lazy, useEffect } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'

const HealthMap = lazy(() => import('@pages/HealthMap'))

export default function Router() {
  const [isAuthenticated, user] = useAuthStore((s) => [s.isAuthenticated, s.user])

  const landingPage = '/' + getLandingPage(user?.countryCode)

  const { isPending } = useHandleJwtTokenExpiry()

  useEffect(() => {
    if (window?.clarity) window.clarity('set', 'email', user?.email)
  }, [user?.email])

  return useRoutes([
    { path: '/*', element: <Navigate to="/auth" /> },
    { path: '/auth', element: <Auth /> },
    {
      path: '/playground',
      element: (
        <HelmetWrapper title="Playground">
          <Playground />
        </HelmetWrapper>
      ),
    },
    {
      path: '/',
      element: isPending ? (
        <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
          <LinearProgress sx={{ width: 300 }} />
        </Box>
      ) : isAuthenticated() ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to="/auth" />
      ),
      children: [
        { path: '/', element: <Navigate to={landingPage} /> },
        {
          path: 'settings',
          element: <HelmetWrapper title="Settings - Osiris | Cropsy Technologies">{<SettingsPage />}</HelmetWrapper>,
        },
        {
          path: 'health',
          element: <HelmetWrapper title="Health - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/health', element: <Navigate to="reporting" /> },
            {
              path: 'map',
              element: (
                <Suspense fallback={<pre>Loading Map...</pre>}>
                  <HealthMap />
                </Suspense>
              ),
            },
            { path: 'reporting', element: <HealthReportingPage /> },
          ],
        },
        {
          path: 'yield',
          element: <HelmetWrapper title="Yield - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/yield', element: <Navigate to="reporting" /> },
            { path: 'reporting', element: <YieldReportingPage /> },
          ],
        },
        {
          path: 'pruning',
          element: <HelmetWrapper title="Pruning - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/pruning', element: <Navigate to="reporting" /> },
            { path: 'reporting', element: <PruningReportingPage /> },
            { path: 'summary', element: <PruningSummaryPage /> },
          ],
        },
        {
          path: 'land',
          element: <HelmetWrapper title="Land - Osiris | Cropsy Technologies">{<Outlet />}</HelmetWrapper>,
          children: [
            { path: '/land', element: <Navigate to="reporting" /> },
            { path: 'reporting', element: <LandReportingPage /> },
            { path: 'summary', element: <LandSummaryPage /> },
          ],
        },
      ],
    },
  ])
}
