import { GridLocaleText } from '@mui/x-data-grid-pro'
import { useTranslate } from './useLocales'

export default function useDataGridLocaleText() {
  const { t } = useTranslate()

  const localeText: Partial<GridLocaleText> = {
    toolbarColumns: t('columns'),
    toolbarFilters: t('filters'),
    toolbarDensity: t('density'),
    toolbarExport: t('export'),
    noRowsLabel: t('no_data'),
    columnsManagementShowHideAllText: t('show_hide_all'),
    columnsManagementReset: t('reset'),
    columnsManagementSearchTitle: t('search'),
    filterPanelAddFilter: t('add_filter'),
    filterPanelRemoveAll: t('remove_all'),
    filterPanelColumns: t('columns'),
    filterPanelOperator: t('operator'),
    toolbarDensityCompact: t('compact'),
    toolbarDensityStandard: t('standard'),
    toolbarDensityComfortable: t('comfortable'),
    toolbarExportPrint: t('print'),
    toolbarExportCSV: 'CSV',
    filterOperatorIs: t('is'),
    filterOperatorNot: t('is_not'),
    filterOperatorIsEmpty: t('is_empty'),
    filterOperatorIsNotEmpty: t('is_not_empty'),
    filterOperatorStartsWith: t('starts_with'),
    filterOperatorIsAnyOf: t('is_any_of'),
    filterOperatorAfter: t('after'),
    filterOperatorOnOrAfter: t('on_or_after'),
    filterOperatorBefore: t('before'),
    filterOperatorOnOrBefore: t('on_or_before'),
    filterValueAny: t('any'),
    filterValueTrue: t('true'),
    filterValueFalse: t('false'),
  }
  return { localeText, t }
}
