import * as Sentry from '@sentry/react'
import useAuthStore from '@stores/auth'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useEffect } from 'react'

type JwtPayload = {
  exp: number
}

export function useHandleJwtTokenExpiry() {
  const [user, setUser] = useAuthStore((s) => [s.user, s.setUser])

  const { mutate, isPending } = useMutation({
    mutationKey: ['refreshToken'],
    mutationFn: () =>
      axios.post(import.meta.env.VITE_CROPSY_BASE_URL + '/refresh-token', null, {
        headers: { Authorization: `Bearer ${user?.refreshToken}` },
      }),
    onSuccess: (res) => {
      setUser(res.data)
      Sentry.setUser({ email: res.data.email, fullName: res.data.name })
    },
    onError: () => {
      setUser(null)
      window.location.href = '/auth'
    },
  })

  useEffect(() => {
    const checkTokenExpiry = () => {
      // Multiply by 1000 to convert seconds to milliseconds, as Date.now() returns milliseconds
      const accessTokenExp = user ? jwtDecode<JwtPayload>(user.token).exp * 1000 : null
      const refreshTokenExp = user ? jwtDecode<JwtPayload>(user.refreshToken).exp * 1000 : null
      const currentTime = Date.now()

      if (!accessTokenExp || !refreshTokenExp) return

      // Check if refresh token is expired
      if (refreshTokenExp < currentTime) {
        setUser(null)
        window.location.href = '/auth'
      }

      // If access token is expiring in 5 minutes (300,000 milliseconds) or less,
      // OR if access token is already expired, then refresh the token
      if (accessTokenExp - currentTime <= 300_000 || accessTokenExp < currentTime) {
        mutate()
      }
    }
    // Immediately check token expiry upon component mount
    checkTokenExpiry()

    const tokenCheckInterval = setInterval(checkTokenExpiry, 10_000) // Check every 3 minute
    return () => clearInterval(tokenCheckInterval) // Clean up on component unmount

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, mutate, setUser])

  return { isPending }
}
