import { Text, View } from '@react-pdf/renderer'
import { ColorRange } from '@typings/common'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { colorByAssumedCaneTarget } from '@utils/utility-fns'
import { styles as s } from './utils'

type CaneCountTilesProps = {
  data: PruningRowStatsAggregated
  range: ColorRange
}

const CaneCountTiles = ({ data, range }: CaneCountTilesProps) => {
  const assumedCaneTarget = data['Assumed Cane Target'] as 1 | 2 | 3 | 4

  const Tile = ({ title, value, pct, bgcolor }: { title: string; value: number; pct: number; bgcolor?: string }) => (
    <View style={[s.tile, { backgroundColor: bgcolor, color: range.contrastText }]}>
      <Text style={{ fontSize: 12 }}>{title}</Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Text style={{ fontSize: 16 }}>{Intl.NumberFormat('en-NZ').format(value)}</Text>
        <View style={{ backgroundColor: 'white', padding: '2px 3px', height: 14 }}>
          <Text style={{ color: '#475569', fontSize: 9, lineHeight: 1 }}>{pct}%</Text>
        </View>
      </View>
    </View>
  )

  return (
    <View style={{ flexDirection: 'row', gap: 8 }}>
      {assumedCaneTarget >= 4 && (
        <Tile
          title="4 Cane"
          value={data['4 Cane']}
          pct={data['4 Cane%']}
          bgcolor={colorByAssumedCaneTarget(assumedCaneTarget, '4_cane', range)}
        />
      )}
      {assumedCaneTarget >= 3 && (
        <Tile
          title="3 Cane"
          value={data['3 Cane']}
          pct={data['3 Cane%']}
          bgcolor={colorByAssumedCaneTarget(assumedCaneTarget, '3_cane', range)}
        />
      )}
      {assumedCaneTarget >= 2 && (
        <Tile
          title="2 Cane"
          value={data['2 Cane']}
          pct={data['2 Cane%']}
          bgcolor={colorByAssumedCaneTarget(assumedCaneTarget, '2_cane', range)}
        />
      )}
      <Tile
        title="1 Cane"
        value={data['1 Cane']}
        pct={data['1 Cane%']}
        bgcolor={colorByAssumedCaneTarget(assumedCaneTarget, '1_cane', range)}
      />
    </View>
  )
}

export default CaneCountTiles
