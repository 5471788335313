import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro'
import { BlockDto, PruningStyleUnion } from '@typings/dtos/block'
import { calculateBlockPotential, createFormatter } from '@utils/utility-fns'
import { TFunction } from 'i18next'

export const pruningCols = (blocksAsMap: Map<number, BlockDto>, pruningStyle: PruningStyleUnion, t: TFunction): GridColDef[] => {
  const sharedCols: GridColDef[] = [
    { field: 'scanDate', headerName: 'Date', valueGetter: (value) => new Date(value), type: 'date', width: 100 },
    { field: 'blockId', headerName: t('block'), width: 150, valueGetter: (value) => blocksAsMap.get(value)?.name },
    { field: 'rowNumber', headerName: t('row'), width: 70, type: 'number' },
  ]

  const bowCols: GridColDef[] = [
    {
      field: 'normalVines',
      headerName: t('normal_vines'),
      width: 130,
      valueGetter: (_, row) => row.stats['normal Vines'],
      type: 'number',
    },
    {
      field: 'youngVines',
      headerName: t('young_vines'),
      width: 120,
      valueGetter: (_, row) => row.stats['young Vines'],
      type: 'number',
    },
    {
      field: 'missingVines',
      headerName: t('missing_vines'),
      width: 150,
      valueGetter: (_, row) => row.stats['missing Vines'],
      type: 'number',
    },
    {
      field: 'unknownVines',
      headerName: t('unknown'),
      width: 90,
      valueGetter: (_, row) => row.stats['unknown Vines'],
      type: 'number',
    },
    {
      field: 'lowQualityVines',
      headerName: t('low_quality'),
      width: 120,
      valueGetter: (_, row) => row.stats['bad Quality Vines'],
      type: 'number',
    },
  ]

  const spurCols: GridColDef[] = [
    {
      field: 'missingCordon1',
      headerName: '1',
      width: 100,
      valueGetter: (_, row) => row.stats['missing Cordons 1'],
      type: 'number',
    },
    {
      field: 'missingCordon2',
      headerName: '2',
      width: 100,
      valueGetter: (_, row) => row.stats['missing Cordons 2'],
      type: 'number',
    },
    {
      field: 'budsPerVineLowerQuartile',
      headerName: t('lower_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Vine LQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerVineUpperQuartile',
      headerName: t('upper_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Vine UQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerVineMean',
      headerName: t('mean'),
      width: 160,
      valueGetter: (_, row) => row.stats['buds Per Vine Mean'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerVineMedian',
      headerName: t('median'),
      width: 80,
      valueGetter: (_, row) => row.stats['buds Per Vine Median'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerSpurPerVineLQ',
      headerName: t('lower_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Spur Per Vine LQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerSpurPerVineUQ',
      headerName: t('upper_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Spur Per Vine UQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerSpurPerVineMean',
      headerName: t('mean'),
      width: 160,
      valueGetter: (_, row) => row.stats['buds Per Spur Per Vine Mean'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerSpurPerVineMedian',
      headerName: t('median'),
      width: 80,
      valueGetter: (_, row) => row.stats['buds Per Spur Per Vine Median'].toFixed(1),
      type: 'number',
    },
    {
      field: 'spursPerVineLQ',
      headerName: t('lower_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['spurs Per Vine LQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'spursPerVineUQ',
      headerName: t('upper_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['spurs Per Vine UQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'spursPerVineMean',
      headerName: t('mean'),
      width: 120,
      valueGetter: (_, row) => row.stats['spurs Per Vine Mean'].toFixed(1),
      type: 'number',
    },
    {
      field: 'spursPerVineMedian',
      headerName: t('median'),
      width: 80,
      valueGetter: (_, row) => row.stats['spurs Per Vine Median'].toFixed(1),
      type: 'number',
    },
  ]

  const caneCols: GridColDef[] = [
    {
      field: 'deadVines',
      headerName: `0 ${t('canes')}/${t('dead')}`,
      width: 130,
      valueGetter: (_, row) => row.stats['dead Vines'],
      type: 'number',
    },
    {
      field: 'productivePercent',
      headerName: t('productive_%'),
      width: 130,
      valueGetter: (_, row) => row.stats['productive%'].toFixed(1),
      type: 'number',
    },
    {
      field: 'prunedToTargetPercent',
      headerName: t('pruned_to_target_%'),
      width: 160,
      valueGetter: (_, row) => row.stats['pruned To Target%'].toFixed(1),
      type: 'number',
    },
    {
      field: 'notPrunedToTargetPercent',
      headerName: t('not_pruned_to_target_%'),
      width: 170,
      valueGetter: (_, row) => row.stats['not Pruned To Target%'].toFixed(1),
      type: 'number',
    },
    {
      field: 'assumedCaneTarget',
      headerName: t('assumed_cane_target'),
      width: 220,
      valueGetter: (_, row) => row.stats['assumed Cane Target'],
      type: 'number',
    },
    {
      field: '1Cane',
      headerName: t('1_cane'),
      width: 90,
      valueGetter: (_, row) => row.stats['1 Cane'],
      type: 'number',
    },
    {
      field: '2Cane',
      headerName: t('2_cane'),
      width: 100,
      valueGetter: (_, row) => row.stats['2 Cane'],
      type: 'number',
    },
    {
      field: '3Cane',
      headerName: t('3_cane'),
      width: 100,
      valueGetter: (_, row) => row.stats['3 Cane'],
      type: 'number',
    },
    {
      field: '4Cane',
      headerName: t('4_cane'),
      width: 100,
      valueGetter: (_, row) => row.stats['4 Cane'],
      type: 'number',
    },
    {
      field: 'budsPerVineLowerQuartile',
      headerName: t('lower_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Vine LQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerVineUpperQuartile',
      headerName: t('upper_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Vine UQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerVineMean',
      headerName: t('mean'),
      width: 170,
      valueGetter: (_, row) => row.stats['buds Per Vine Mean'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerVineMedian',
      headerName: t('median'),
      width: 80,
      valueGetter: (_, row) => row.stats['buds Per Vine Median'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerCaneLowerQuartile',
      headerName: t('lower_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Cane LQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerCaneUpperQuartile',
      headerName: t('upper_quartile'),
      width: 120,
      valueGetter: (_, row) => row.stats['buds Per Cane UQ'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerCaneMean',
      headerName: t('mean'),
      width: 180,
      valueGetter: (_, row) => row.stats['buds Per Cane Mean'].toFixed(1),
      type: 'number',
    },
    {
      field: 'budsPerCaneMedian',
      headerName: t('median'),
      width: 80,
      valueGetter: (_, row) => row.stats['buds Per Cane Median'].toFixed(1),
      type: 'number',
    },
    {
      field: 'canesLaidPct',
      headerName: t('canes_laid_%'),
      width: 140,
      valueGetter: (_, row) => calculateBlockPotential(row.stats, true),
      valueFormatter: createFormatter(),
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'canesNotLaidPct',
      headerName: t('canes_not_laid_%'),
      width: 160,
      valueGetter: (_, row) => (100 - Number(calculateBlockPotential(row.stats, true))).toFixed(1),
      valueFormatter: createFormatter(),
      align: 'right',
      headerAlign: 'right',
    },
  ]

  const colsByPruningStyle: Record<PruningStyleUnion, GridColDef[]> = {
    Bow: [
      ...sharedCols,
      ...bowCols,
      {
        field: 'BudsPerVine',
        headerName: t('calculated_buds_per_vine'),
        width: 220,
        valueGetter: (_, row) => row.stats['buds Per Vine'].toFixed(1),
        type: 'number',
      },
    ],
    Spur: [...sharedCols, ...bowCols, ...spurCols],
    Cane: [...sharedCols, ...bowCols, ...caneCols],
  }

  return colsByPruningStyle[pruningStyle]
}

export const pruningColsGrouping = (t: TFunction): GridColumnGroupingModel => [
  {
    groupId: 'budsPerVine',
    headerName: t('buds_per_vine'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-1',
    children: [
      { field: 'budsPerVineLowerQuartile' },
      { field: 'budsPerVineUpperQuartile' },
      { field: 'budsPerVineMean' },
      { field: 'budsPerVineMedian' },
    ],
  },
  {
    groupId: 'budsPerCane',
    headerName: t('buds_per_cane'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-2',
    children: [
      { field: 'budsPerCaneLowerQuartile' },
      { field: 'budsPerCaneUpperQuartile' },
      { field: 'budsPerCaneMean' },
      { field: 'budsPerCaneMedian' },
    ],
  },
  {
    groupId: 'missingCordon',
    headerName: t('missing_cordons'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-2',
    children: [{ field: 'missingCordon1' }, { field: 'missingCordon2' }],
  },
  {
    groupId: 'budsPerSpur',
    headerName: t('buds_per_spur'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-2',
    children: [
      { field: 'budsPerSpurPerVineLQ' },
      { field: 'budsPerSpurPerVineUQ' },
      { field: 'budsPerSpurPerVineMean' },
      { field: 'budsPerSpurPerVineMedian' },
    ],
  },
  {
    groupId: 'SpursPerVine',
    headerName: t('spurs_per_vine'),
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-1',
    children: [
      { field: 'spursPerVineLQ' },
      { field: 'spursPerVineUQ' },
      { field: 'spursPerVineMean' },
      { field: 'spursPerVineMedian' },
    ],
  },
]
