import { Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { format, parseISO } from 'date-fns'
import { styles as s } from './utils'

type TitlePageProps = {
  data: PruningRowStatsAggregated[]
}

const TitlePage = ({ data }: TitlePageProps) => {
  const findDateRange = () => {
    const oldestStartDate = data
      .map((block) => block['Start Date'])
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]
    const newestEndDate = data.map((block) => block['End Date']).sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]
    if (!oldestStartDate || !newestEndDate) return ''
    const startDate = format(parseISO(oldestStartDate), 'dd MMM yyyy')
    const endDate = format(parseISO(newestEndDate), 'dd MMM yyyy')
    if (startDate === endDate) return startDate
    return `${startDate} \u00A0\u2014\u00A0 ${endDate}`
  }

  return (
    <Page size="A4" style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1 }}>
        <Image style={{ width: '100%', height: '100%', objectFit: 'cover' }} src="/images/pruning-report-cover.png" />
      </View>
      <View style={s.layout}>
        <View style={{ marginTop: 100 }}>
          <Text style={{ color: '#23733A' }}>PRUNING REPORT</Text>
          <View style={[s.dateWrapper, { fontSize: 12 }]}>
            <Text style={{ margin: 0, padding: 0 }}>{findDateRange()}</Text>
          </View>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ color: '#475569', fontSize: 12, marginBottom: 6 }}>BLOCKS:</Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 6 }}>
            {data.map((block) => (
              <Link key={block['Block Id']} src={`#${block['Block Id']}`} style={{ fontSize: 12, color: '#0284C7' }}>
                {block['Block Name']}
              </Link>
            ))}
          </View>
        </View>
      </View>
      <Image src="/logos/head-logo.png" style={s.footerLogo} />
    </Page>
  )
}

export default TitlePage
