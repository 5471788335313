import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import BoxplotChart from '@components/echarts/charts/BoxplotChart'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { Pruning_BudsSortOptions } from '@utils/options'
import { useEffect, useState } from 'react'

type CanePruningBudsStatsBoxplotChartProps = {
  data: PruningRowStatsAggregated[]
  dataBy: 'cane' | 'vine'
}

const CanePruningBudsStatsBoxplotChart = ({ data, dataBy }: CanePruningBudsStatsBoxplotChartProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [canePruningBudsStats, setCanePruningBudsStats] = useState<PruningRowStatsAggregated[]>([])
  const [activeBudsStatsView, setActiveBudsStatsView] = useState<'budsPerVine' | 'budsPerCane'>('budsPerVine')

  const { t } = useTranslate()

  const dataByActiveView = {
    budsPerVine: canePruningBudsStats.map((stat) => {
      return [
        stat['Buds Per Vine LQ'],
        stat['Buds Per Vine LQ'],
        stat['Buds Per Vine Mean'],
        stat['Buds Per Vine UQ'],
        stat['Buds Per Vine UQ'],
      ]
    }),
    budsPerCane: canePruningBudsStats.map((stat) => {
      return [
        stat['Buds Per Cane LQ'],
        stat['Buds Per Cane LQ'],
        stat['Buds Per Cane Mean'],
        stat['Buds Per Cane UQ'],
        stat['Buds Per Cane UQ'],
      ]
    }),
  }

  useEffect(() => {
    setCanePruningBudsStats(data)
  }, [data])

  useEffect(() => {
    setActiveBudsStatsView(dataBy === 'cane' ? 'budsPerCane' : 'budsPerVine')
  }, [dataBy])

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setCanePruningBudsStats((prev) =>
        prev.sort((a, b) =>
          activeSort === 'Block Name-asc'
            ? b['Block Name'].localeCompare(a['Block Name'])
            : a['Block Name'].localeCompare(b['Block Name'])
        )
      )
    }
    if (activeSort.startsWith('Block Name')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setCanePruningBudsStats((prev) => {
        //text before the first hyphen
        const prefix = dataBy === 'cane' ? 'Buds Per Cane ' : 'Buds Per Vine '
        const sortKey = (prefix + activeSort.split('-')[0]) as keyof PruningRowStatsAggregated
        return [
          ...prev.sort((a, b) => {
            const valueA = a[sortKey] as number
            const valueB = b[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        ]
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort, dataBy, data])

  const isStatsByVine = dataBy === 'vine'

  return (
    <Box pb={4}>
      <SummaryTileWrapper
        label={isStatsByVine ? 'bud_stats_per_vine' : 'bud_stats_per_cane'}
        width="100%"
        height={500}
        tooltipMsg={'pruning_page.summary.cane.tooltip_buds_stats_by_vine'}
        headerComponent={
          <SortBlocksMenu
            id="sort-buds-stats-data"
            menuItems={Pruning_BudsSortOptions}
            value={activeSort}
            onClick={(val) => setActiveSort(val)}
            menuArrowPositionRight={110}
          />
        }
      >
        <Box py={4}>
          <BoxplotChart
            data={dataByActiveView[activeBudsStatsView]}
            categoryData={canePruningBudsStats.map((stat) => stat['Block Name'])}
            tooltipFormatter={(params) => {
              // @ts-expect-error - TS doesn't know that params is an object
              const blockName = params[0].name
              // @ts-expect-error - TS doesn't know that params is an object
              const [, , lq, mean, uq] = params[0].value
              return `<b>${blockName}</b><br />${t('lower_quartile')}: ${lq}<br />${t('mean')}: ${mean}<br />${t('upper_quartile')}: ${uq}`
            }}
          />
        </Box>
      </SummaryTileWrapper>
    </Box>
  )
}

export default CanePruningBudsStatsBoxplotChart
