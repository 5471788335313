import DrawerHead from '@components/drawer/DrawerHead'
import ToggleButtonGroup from '@components/form/ToggleIconButtonGroup'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UserApi } from '@services/api'
import { allLangs } from '@services/locales/config-lang'
import useAuthStore from '@stores/auth'
import { useMutation } from '@tanstack/react-query'
import { ThemeMode } from '@typings/common'
import { themeOptions } from '@utils/component-utils'

type SettingsDrawerProps = {
  open: boolean
  onClose: () => void
}

const SettingsDrawer = ({ open, onClose }: SettingsDrawerProps) => {
  const { t, onChangeLang } = useTranslate()
  const [userSettings, setUserSettings] = useAuthStore((s) => [s.userSettings, s.setUserSettings])
  const handleAxiosError = useHandleAxiosError()

  const { mutate } = useMutation({
    mutationKey: ['userSettings'],
    mutationFn: UserApi.postUserSettings,
    onSuccess: (data) => {
      setUserSettings(data)
      onChangeLang(data.language)
    },
    onError: (error) => handleAxiosError(error),
  })

  if (!userSettings) return null

  return (
    <Drawer anchor="right" open={open} onClose={onClose} slotProps={{ backdrop: { invisible: true } }}>
      <Stack gap={2} width={300}>
        <DrawerHead label={t('customize')} onClose={onClose} icon="teenyicons:toggle-solid" />
        <Box px={2} display="grid" gap={4}>
          <Box>
            <Typography fontWeight={500} fontSize={14} color="grey.700" mb={1}>
              {t('language')}
            </Typography>
            <ToggleButtonGroup
              options={allLangs}
              selectedValue={userSettings.language || 'uk'}
              onClick={(value) => mutate({ ...userSettings, language: value })}
            />
          </Box>
          <Box>
            <Typography fontWeight={500} fontSize={14} color="grey.700" mb={1}>
              {t('colour')}
            </Typography>
            <ToggleButtonGroup
              options={themeOptions}
              selectedValue={userSettings.theme || 'standard'}
              onClick={(value) => mutate({ ...userSettings, theme: value as ThemeMode })}
            />
          </Box>
        </Box>
      </Stack>
    </Drawer>
  )
}

export default SettingsDrawer
