import FallbackMessage from '@components/sections/FallbackMessage'
import Box from '@mui/material/Box'
import { BlocksDataConditionEnum } from '@typings/component.enums'
import { ReactNode } from 'react'

type SummaryPageWrapperProps = {
  children: ReactNode
  pageCondition: Record<BlocksDataConditionEnum, boolean>
  dataLength: number
}

const SummaryPageWrapper = ({ children, pageCondition, dataLength }: SummaryPageWrapperProps) => {
  return (
    <Box width="100%">
      {pageCondition.NoBlock ? (
        <FallbackMessage icon="grommet-icons:select" message="msg_no_blocks_selected" />
      ) : pageCondition.BlocksButNoData && dataLength === 0 ? (
        <FallbackMessage icon="grommet-icons:document-missing" message="msg_blocks_selected_but_no_data" />
      ) : (
        <Box display="grid" gap={3} maxWidth={1320}>
          {children}
        </Box>
      )}
    </Box>
  )
}

export default SummaryPageWrapper
