import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import Box from '@mui/material/Box'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { numberFmtThousandSeparator } from '@utils/utility-fns'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'
import SpurPruningVineStatsBoxplotChart from './charts/SpurPruningVineStatsBoxplotChart'

type SpurPrunedSectionProps = {
  data: PruningRowStatsAggregated[]
}

const SpurPrunedSection = ({ data }: SpurPrunedSectionProps) => {
  const totalVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Total Vines'], 0)
  const totalYoungVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Young Vines'], 0)
  const totalMissingVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Missing Vines'], 0)
  const totalMissingCordons1 = data.reduce(
    (acc: number, curr: PruningRowStatsAggregated) => acc + (Number(curr['1 Missing Cordon']) || 0),
    0
  )
  const totalMissingCordons2 = data.reduce(
    (acc: number, curr: PruningRowStatsAggregated) => acc + (Number(curr['2 Missing Cordons']) || 0),
    0
  )

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent aggregatedData={data} />}
          tooltipInfo={'pruning_page.summary.tooltip_showing_stats_for'}
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="total" color="info.dark" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalYoungVines)} label="young_vines" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={totalMissingCordons1} label="1_missing_cordon" color="error.main" />
            <HighlightInfo value={totalMissingCordons2} label="2_missing_cordons" color="error.main" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SpurPruningVineStatsBoxplotChart data={data} />
    </>
  )
}

export default SpurPrunedSection
