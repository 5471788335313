import Menu, { MenuProps } from '@mui/material/Menu'

type MenuBaseProps = MenuProps & {
  menuArrowPositionRight?: number
}

const MenuBase = ({ menuArrowPositionRight = 66, ...props }: MenuBaseProps) => {
  return (
    <Menu
      disableAutoFocusItem
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            mt: 1.5,
            overflow: 'visible',
            filter: 'drop-shadow(0px 6px 16px rgba(0,0,0,0.32))',
            '&:before': {
              width: 10,
              height: 10,
              content: '""',
              display: 'block',
              position: 'absolute',
              zIndex: 0,
              top: 0,
              right: menuArrowPositionRight,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      {...props}
    />
  )
}

export default MenuBase
