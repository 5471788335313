import { useLocales } from '@hooks/useLocales'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'

type Props = {
  children: React.ReactNode
}

const LocalizationProvider = ({ children }: Props) => {
  const { currentLang } = useLocales()

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.adapterLocale}>
      {children}
    </MuiLocalizationProvider>
  )
}

export default LocalizationProvider
