import TextField from '@components/form/TextField'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { useTranslate } from '@hooks/useLocales'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { UserApi } from '@services/api'
import useAuthStore from '@stores/auth'
import useVineyardStore from '@stores/vineyard'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

type DialogCreateFavouriteProps = {
  open: boolean
  onClose: VoidFunction
}

const DialogCreateFavourite = ({ open, onClose }: DialogCreateFavouriteProps) => {
  const [favName, setFavName] = useState('')
  const { t } = useTranslate()
  const handleAxiosError = useHandleAxiosError()

  const [userSettings, setUserSettings] = useAuthStore((s) => [s.userSettings, s.setUserSettings])
  const [selectedBlocksLocal] = useVineyardStore((s) => [s.selectedBlocksLocal])

  const { mutate } = useMutation({
    mutationKey: ['userSettings'],
    mutationFn: UserApi.postUserSettings,
    onSuccess: (data) => {
      setUserSettings(data)
      onClose()
      setFavName('')
    },
    onError: (error) => {
      onClose()
      setFavName('')
      handleAxiosError(error)
    },
  })

  if (!userSettings) return null

  const { favouriteViews } = userSettings
  const isFavNameAvailable = favouriteViews.some((fav) => fav.name === favName)

  return (
    <Dialog open={open} onClose={onClose} disableRestoreFocus>
      <DialogTitle color="grey.800">{t('add_to_favourites')}</DialogTitle>
      <DialogContent sx={{ width: 350 }}>
        <DialogContentText>{t('set_filter_name')}</DialogContentText>
        <TextField
          autoFocus
          autoComplete="off"
          id="user-setting-filter"
          variant="standard"
          label={t('filter_name')}
          sx={{ mt: 4, height: 70 }}
          value={favName}
          onChange={(e) => setFavName(e.target.value)}
          error={isFavNameAvailable}
          helperText={isFavNameAvailable ? 'Name already exists' : null}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: 'grey.800' }} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          sx={{ color: 'primary.dark' }}
          disabled={!favName || isFavNameAvailable}
          onClick={() =>
            mutate({
              ...userSettings,
              favouriteViews: [
                ...favouriteViews,
                {
                  name: favName,
                  blocks: selectedBlocksLocal.map((block) => {
                    return {
                      id: block.id,
                      name: block.name,
                      vineyardId: block.vineyardId,
                      varietyId: block.varietyId,
                    }
                  }),
                },
              ],
            })
          }
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCreateFavourite
