import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { ColorRange } from '@typings/common'
import { BlockNamesWithSameValue } from '@typings/component'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { DEFAULT_BLOCK_NAMES_WITH_SAME_VAL } from '@utils/constants'
import { LandBow_VineStatsSortOptions } from '@utils/options'
import { findHighestValueBlockNames, numberFmtThousandSeparator } from '@utils/utility-fns'
import { TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import i18next from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'

type LandBowSectionProps = {
  data: PruningRowStatsAggregated[]
}

const LandBowSection = ({ data }: LandBowSectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [mostMissingVines, setMostMissingVines] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [mostReplants, setMostReplants] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [vineStatsDataset, setVineStatsDataset] = useState({
    id: 'land-bow-vine-stats-dataset',
    dimensions: ['Block Name', 'Missing Vines%', 'Young Vines%'],
    source: [] as PruningRowStatsAggregated[],
  })

  const { t } = useTranslate()
  const { palette } = useTheme()

  const totalVinesInSelectedBlocks = useMemo(() => data.reduce((acc, curr) => acc + curr['Total Vines'], 0), [data])
  const totalMissingVines = useMemo(() => data.reduce((acc, curr) => acc + curr['Missing Vines'], 0), [data])
  const totalYoungVines = useMemo(() => data.reduce((acc, curr) => acc + curr['Young Vines'], 0), [data])

  const setStates = useCallback(() => {
    setMostMissingVines(findHighestValueBlockNames(data, 'Missing Vines%'))
    setMostReplants(findHighestValueBlockNames(data, 'Young Vines%'))
    setVineStatsDataset((prev) => ({ ...prev, source: [...data] }))
  }, [data])

  useEffect(() => {
    setStates()
  }, [data, setStates])

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setVineStatsDataset((prev) => {
        return {
          ...prev,
          source: prev.source.sort((a, b) => {
            return activeSort === 'Block Name-asc'
              ? b['Block Name'].localeCompare(a['Block Name'])
              : a['Block Name'].localeCompare(b['Block Name'])
          }),
        }
      })
    }
    if (activeSort.startsWith('Block Name')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setVineStatsDataset((prev) => {
        //text before the first hyphen
        const sortKey = activeSort.split('-')[0] as keyof PruningRowStatsAggregated
        return {
          ...prev,
          source: prev.source.sort((a, b) => {
            const valueA = a[sortKey] as number
            const valueB = b[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        }
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort, data])

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent aggregatedData={data} />}
          tooltipInfo="pruning_page.summary.tooltip_showing_stats_for"
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo
              value={numberFmtThousandSeparator(totalVinesInSelectedBlocks)}
              label="total"
              valueFontSize={28}
              color="info.dark"
            />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing" color="error.main" />
            <HighlightInfo
              value={mostMissingVines.value + '%'}
              label="most_missing"
              color="error.main"
              blockNames={mostMissingVines.blockNames}
              labelTooltip="land_page.summary.tooltip_most_missing_vines"
            />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={totalYoungVines} label="replanted" />
            <HighlightInfo
              value={mostReplants.value + '%'}
              label="most_replants"
              blockNames={mostReplants.blockNames}
              labelTooltip="land_page.summary.tooltip_most_replants"
            />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SummaryTileWrapper
        label="vine_stats"
        width="100%"
        height="100%"
        tooltipMsg="land_page.summary.bow.tooltip_vine_stats"
        headerComponent={
          vineStatsDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-vine-stats"
              menuItems={LandBow_VineStatsSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={vineStatsDataset}
            legend={{
              show: true,
              formatter: (name) => {
                // lowercase and replace all spaces with _ and remove % symbol
                const key = name.toLowerCase().replace(/ /g, '_').replace('%', '')
                return t(key)
              },
            }}
            color={[palette.range.negHigh, palette.range.posLow]}
            tooltipFormatter={(p) => tooltipFormatter(p, palette.range)}
            hideLabelValueBelow={0.4}
          />
        </Box>
      </SummaryTileWrapper>
    </>
  )
}

export default LandBowSection

const tooltipFormatter = (params: TopLevelFormatterParams, range: ColorRange) => {
  const { t } = i18next
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value } = params[0]
  let result = `<div style="margin-bottom: 6px; width: 200px">
                      <div style="text-align: left; font-weight: 600; font-size: 14px;">${name}</div>
                </div>`

  const vineStats = [
    { key: 'missing_vines', value: value['Missing Vines'], color: range.negHigh },
    { key: 'young_vines', value: value['Young Vines'], color: range.posLow },
  ]

  vineStats.forEach((cane) => {
    if (cane.value !== 0) {
      result += `<div style="display: flex; color: ${range.contrastText}; justify-content: space-between; gap: 2; background-color: ${cane.color}">
            <b style="width: 60px; padding:6px 0 6px 8px;">${t(cane.key)}:</b>
            <b style="padding:6px 8px 6px 8px">${cane.value}</b>
          </div>`
    }
  })

  const { 'Unknown Vines': unknownVinesCount, 'Low Quality Vines': lowQualityVinesCount } = value

  if (unknownVinesCount > 0 || lowQualityVinesCount > 0) {
    result += `<div style="margin-top: 12px; border-top: 1px solid lightgrey; margin-bottom: 6px; width: 200px">
                      <div style="text-align: left; font-size: 14px; padding-top: 8px;">${t('excluded')}</div>
                 </div> `
  }

  if (unknownVinesCount > 0)
    result += `<div style="display: flex; color: #64748B; justify-content: space-between; gap: 2; background-color: #F3F4F6">
            <b style="width: 60px; padding:6px 0 6px 8px">${t('unknown')}:</b>
            <b style="padding:6px 8px 6px 8px">${unknownVinesCount}</b>
          </div>`

  if (lowQualityVinesCount > 0)
    result += `<div style="display: flex; color: #64748B; justify-content: space-between; gap: 2; background-color: #F3F4F6">
            <b style="width: 60px; padding:6px 0 6px 8px">${t('low_quality')}:</b>
            <b style="padding:6px 8px 6px 8px">${lowQualityVinesCount}</b>
          </div>`

  return result
}
