import { Text, View } from '@react-pdf/renderer'
import { RowStatsPruningDto } from '@typings/dtos/vine-row-stats'

import { flexByAssumedCaneTarget, styles as s } from './utils'

type TableRowProps = {
  row: RowStatsPruningDto
  i: number
  assumedCaneTarget: number
}

const TableRow = ({ row, i, assumedCaneTarget }: TableRowProps) => {
  const { stats } = row

  const style = {
    ...s.tableCell,
    flex: flexByAssumedCaneTarget[assumedCaneTarget as 1 | 2 | 3 | 4],
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: i % 2 === 0 ? 'white' : '#F5F5F5',
      }}
    >
      <View style={style}>
        <Text>{row.rowNumber}</Text>
      </View>
      <View style={style}>
        <Text>{Math.round(stats['pruned To Target%'])}%</Text>
      </View>
      {assumedCaneTarget >= 4 && (
        <View style={style}>
          <Text>{stats['4 Cane']}</Text>
        </View>
      )}
      {assumedCaneTarget >= 3 && (
        <View style={style}>
          <Text>{stats['3 Cane']}</Text>
        </View>
      )}
      {assumedCaneTarget >= 2 && (
        <View style={style}>
          <Text>{stats['2 Cane']}</Text>
        </View>
      )}
      <View style={style}>
        <Text>{stats['1 Cane']}</Text>
      </View>
      <View style={style}>
        <Text>{stats['buds Per Vine Mean'].toFixed(1)}</Text>
      </View>
      <View style={style}>
        <Text>{stats['buds Per Cane Mean'].toFixed(1)}</Text>
      </View>
    </View>
  )
}

export default TableRow
