import Iconify from '@components/data-display/Iconify'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

type SummaryTileWrapperProps = {
  label: string
  children: ReactNode
  width?: number | string
  height?: number | string
  headerComponent?: ReactNode
  tooltipMsg?: string
}

const SummaryTileWrapper = ({
  label,
  children,
  width = 260,
  height = 120,
  headerComponent,
  tooltipMsg,
}: SummaryTileWrapperProps) => {
  const { t } = useTranslate()

  return (
    <Paper
      variant="outlined"
      sx={{ width, height, p: 1.6, display: 'grid', borderRadius: 1.6, bgcolor: '#FAFAFA', borderColor: '#CBD5E1' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={13} fontWeight={600} color="info.dark" width="fit-content" sx={{ userSelect: 'none' }}>
          {t(label)}
        </Typography>
        <Box display="flex" gap={2}>
          {headerComponent}
          {tooltipMsg && (
            <Tooltip arrow title={t(tooltipMsg)}>
              <Iconify icon="ooui:info-filled" color="grey.500" width={17} />
            </Tooltip>
          )}
        </Box>
      </Box>
      {children}
    </Paper>
  )
}

export default SummaryTileWrapper
