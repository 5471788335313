import devtoolsDetect from 'devtools-detect'
import { useEffect, useState } from 'react'

export function useDevToolsStatus() {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtoolsDetect.isOpen)

  useEffect(() => {
    const handleChange = (event: any) => {
      setIsDevToolsOpen(event.detail.isOpen)
    }

    window.addEventListener('devtoolschange', handleChange)

    return () => {
      window.removeEventListener('devtoolschange', handleChange)
    }
  }, [])

  return isDevToolsOpen
}
