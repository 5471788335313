import { VineRowStatsApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { aggregatedPruningRowStatsData } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetRowStatsForCharts() {
  const [aggregatedData, setAggregatedData] = useState<{
    bow: PruningRowStatsAggregated[]
    cane: PruningRowStatsAggregated[]
    spur: PruningRowStatsAggregated[]
  }>({ cane: [], spur: [], bow: [] })

  const [selectedBlocks, startDate, endDate, blocksAsMap, canePrunedBlockIds, spurPrunedBlockIds, bowPrunedBlockIds] =
    useVineyardStore((s) => [
      s.selectedBlocks,
      s.startDate,
      s.endDate,
      s.blocksAsMap,
      s.canePrunedBlockIds,
      s.spurPrunedBlockIds,
      s.bowPrunedBlockIds,
    ])

  const handleAxiosError = useHandleAxiosError()

  const { data, refetch, isFetching, dataUpdatedAt, error } = useQuery({
    queryKey: ['rowStatsPruning'],
    initialData: [],
    queryFn: () =>
      VineRowStatsApi.getRowStatsPruning({
        blockIds: selectedBlocks.map((block) => block.id),
        dateStart: startDate,
        dateEnd: endDate,
        qualityFilter: true,
      }),
    enabled: false,
  })

  useEffect(() => {
    if (!data) return
    setAggregatedData(aggregatedPruningRowStatsData(data, blocksAsMap, canePrunedBlockIds, spurPrunedBlockIds, bowPrunedBlockIds))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching])

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  return { aggregatedData, isFetching, dataUpdatedAt, selectedBlocks, data }
}
