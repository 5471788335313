import { Text, View, ViewProps } from '@react-pdf/renderer'
import { ColorRange } from '@typings/common'
import { colorByAssumedCaneTarget } from '@utils/utility-fns'
import { flexByAssumedCaneTarget, styles as s } from './utils'

type TableHeadProps = {
  assumedCaneTarget: 1 | 2 | 3 | 4
  range: ColorRange
}

const TableHead = ({ assumedCaneTarget, range }: TableHeadProps) => {
  const THCell = ({ title, style, marker }: { title: string; style: ViewProps['style']; marker?: boolean }) => (
    <View
      style={{
        ...s.tableCell,
        ...style,
        flex: flexByAssumedCaneTarget[assumedCaneTarget],
        fontSize: 8,
        height: 22,
        position: 'relative',
      }}
    >
      <Text>{title}</Text>
      {marker && (
        <View
          style={{ width: 6, height: 6, borderRadius: 4, backgroundColor: '#F97316', position: 'absolute', top: 2, right: 2 }}
        ></View>
      )}
    </View>
  )

  return (
    <View style={{ flexDirection: 'row' }}>
      <THCell title="Row" style={{ backgroundColor: 'grey', color: 'white' }} />
      <THCell title="Target" style={{ backgroundColor: 'grey', color: 'white' }} />
      {assumedCaneTarget >= 4 && (
        <THCell
          title="4"
          style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, '4_cane', range), color: range.contrastText }}
        />
      )}
      {assumedCaneTarget >= 3 && (
        <THCell
          title="3"
          style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, '3_cane', range), color: range.contrastText }}
        />
      )}
      {assumedCaneTarget >= 2 && (
        <THCell
          title="2"
          style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, '2_cane', range), color: range.contrastText }}
        />
      )}
      <THCell
        title="1"
        style={{ backgroundColor: colorByAssumedCaneTarget(assumedCaneTarget, '1_cane', range), color: range.contrastText }}
      />
      <THCell marker title="Buds / Vine" style={{ backgroundColor: '#475569', color: 'white', lineHeight: 1 }} />
      <THCell marker title="Buds / Cane" style={{ backgroundColor: '#475569', color: 'white', lineHeight: 1 }} />
    </View>
  )
}

export default TableHead
