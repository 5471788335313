import { VineRowStatsApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetRowStatsPruningApi() {
  const [selectedBlocks, startDate, endDate] = useVineyardStore((s) => [s.selectedBlocks, s.startDate, s.endDate])

  const handleAxiosError = useHandleAxiosError()

  const { data, refetch, isFetching, dataUpdatedAt, error } = useQuery({
    queryKey: ['rowStatsPruning'],
    initialData: [],
    queryFn: () =>
      VineRowStatsApi.getRowStatsPruning({
        blockIds: selectedBlocks.map((block) => block.id),
        dateStart: startDate,
        dateEnd: endDate,
        qualityFilter: true,
      }),
    enabled: false,
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  return { data, isFetching, dataUpdatedAt }
}
