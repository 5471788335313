import { allLangs, defaultLang } from '@services/locales/config-lang'
import useAuthStore from '@stores/auth'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

export function useLocales() {
  const [userSettings] = useAuthStore((s) => [s.userSettings])

  const currentLang = allLangs.find((lang) => lang.value === userSettings?.language) || defaultLang

  return { allLangs, currentLang }
}

// ----------------------------------------------------------------------

export function useTranslate() {
  const { t, i18n, ready } = useTranslation()

  const onChangeLang = useCallback(
    (newLang: string) => {
      i18n.changeLanguage(newLang)
    },
    [i18n]
  )

  return { t, i18n, ready, onChangeLang }
}
