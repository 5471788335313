import { useTranslate } from '@hooks/useLocales'
import MenuItem from '@mui/material/MenuItem'
import { GridExportMenuItemProps } from '@mui/x-data-grid-pro'

type ReportExportMenuItemProps = {
  downloadPdf: () => void
} & GridExportMenuItemProps<{}>

const ReportExportMenuItem = ({ downloadPdf, hideMenu }: ReportExportMenuItemProps) => {
  const { t } = useTranslate()
  return (
    <MenuItem
      onClick={() => {
        downloadPdf()
        hideMenu?.()
      }}
    >
      {t('pruning_report')} PDF
    </MenuItem>
  )
}

export default ReportExportMenuItem
