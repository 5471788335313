import { ThemeMode } from '@typings/common'

export const primary = { main: '#64C043', contrastText: '#FFFFFF' }

export const standard = {
  range: {
    posHigh: '#34D399',
    posLow: '#60A5FA',
    neutral: '#94A3B8',
    negLow: '#FACC15',
    negHigh: '#F87171',
    contrastText: '#1E293B',
  },
  post: {
    wood: '#795548',
    metal: '#155E75',
    missing: '#292524',
    unknown: '#A1A1AA',
  },
}

export const colorBlind = {
  range: {
    posHigh: '#648FFF',
    posLow: '#785EF0',
    neutral: '#FFB000',
    negLow: '#FE6100',
    negHigh: '#DC267F',
    contrastText: '#FFF',
  },
  post: {
    wood: '#D23B06',
    metal: '#1582A5',
    missing: '#292524',
    unknown: '#A1A1AA',
  },
}

export const info = { light: '#64B5F6', main: '#2196F3', dark: '#37474F', contrastText: '#FFFFFF' }

export function palette(mode: ThemeMode) {
  const _shared = { info, primary }
  const _standard = { ...standard, ..._shared }
  const _colorBlind = { ...colorBlind, ..._shared }

  return mode === 'color-blind' ? _colorBlind : _standard
}
