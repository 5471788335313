import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import useAuthStore from '@stores/auth'
import useVineyardStore from '@stores/vineyard'
import { PruningStyleUnion } from '@typings/dtos/block'
import { ReactNode, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'

type PruningStyleOptionsWrapperProps = {
  children: ReactNode
  blocksCountByPruningStyle: Record<PruningStyleUnion, number>
  statsToggleComponent?: ReactNode
}

const PruningStyleOptionsWrapper = ({
  children,
  blocksCountByPruningStyle,
  statsToggleComponent,
}: PruningStyleOptionsWrapperProps) => {
  const { t } = useTranslate()

  const [pruningStyleOptions, activePruningStyle, setActivePruningStyle] = useVineyardStore(
    useShallow((s) => [s.pruningStyleOptions, s.activePruningStyle, s.setActivePruningStyle])
  )

  const [isSidebarOpen] = useAuthStore((s) => [s.isSidebarOpen])

  useEffect(() => {
    if (!activePruningStyle && pruningStyleOptions.length)
      setActivePruningStyle(pruningStyleOptions[0]?.value as PruningStyleUnion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActivePruningStyle])

  return (
    <Box display="grid" gap={3} mt={9}>
      <Box
        alignItems="center"
        width="100%"
        py={2}
        bgcolor="#FFF"
        position="fixed"
        top={48}
        left={0}
        zIndex={1}
        borderBottom="1px solid #E7E5E4"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)"
      >
        <Box
          maxWidth={1364}
          display="flex"
          justifyContent="space-between"
          ml={isSidebarOpen ? 40 : 6}
          alignItems="end"
          pl={4}
          pr={2}
        >
          <Box display="flex" gap={1.2} height={32}>
            {pruningStyleOptions.map(({ value, label }) => (
              <Box
                key={value}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                  py: 0.4,
                  pl: 1.2,
                  minWidth: 154,
                  fontSize: 14,
                  fontWeight: 500,
                  borderRadius: 1,
                  height: 34,
                  userSelect: 'none',
                  overflow: 'hidden',
                  transition: 'all 0.3s ease-in-out',
                  bgcolor: value === activePruningStyle ? 'primary.dark' : '#E7E5E4',
                  color: value === activePruningStyle ? '#FFFFFF' : '#52525B',
                  cursor: value === activePruningStyle ? 'default' : 'pointer',
                  ':hover': { bgcolor: value === activePruningStyle ? '' : '#D6D3D1' },
                }}
                onClick={() => setActivePruningStyle(value)}
              >
                {t(label)}
                <Box
                  height={34}
                  lineHeight={2.6}
                  px={1.4}
                  fontWeight={500}
                  color="#f4f4f5"
                  bgcolor={blocksCountByPruningStyle[value] > 0 ? '#326022' : '#a3a3a3'}
                  sx={{ transition: 'all 0.3s ease-in-out' }}
                >
                  {blocksCountByPruningStyle[value]}
                  {/* {blocksCountByPruningStyle[value] > 0 ? `${blocksCountByPruningStyle[value]}` : 0} */}
                </Box>
              </Box>
            ))}
          </Box>
          {statsToggleComponent}
        </Box>
      </Box>
      {children}
    </Box>
  )
}

export default PruningStyleOptionsWrapper
