import BlockNameCustomChip from '@components/data-display/BlockNameCustomChip'
import HighlightInfo from '@components/data-display/HighlightInfo'
import { useTranslate } from '@hooks/useLocales'
import useVineyardStore from '@stores/vineyard'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'

type ConditionalStatsTileContentProps = {
  aggregatedData: PruningRowStatsAggregated[]
}

const ConditionalStatsTileContent = ({ aggregatedData }: ConditionalStatsTileContentProps) => {
  const [selectedBlocks] = useVineyardStore((s) => [s.selectedBlocks])
  const { t } = useTranslate()

  const conditions = {
    SINGLE_SELECTED_BLOCK: selectedBlocks.length === 1,
    SAME_COUNT: selectedBlocks.length === aggregatedData.length,
  }

  return conditions.SINGLE_SELECTED_BLOCK ? (
    <BlockNameCustomChip value={aggregatedData[0]?.['Block Name'] || ''} />
  ) : conditions.SAME_COUNT ? (
    <HighlightInfo value={aggregatedData.length} label={t('selected_blocks')} valueFontSize={28} color="info.dark" />
  ) : (
    <HighlightInfo
      value={aggregatedData.length}
      label={t('out_of') + ' ' + selectedBlocks.length + ' ' + t('selected_blocks')}
      valueFontSize={28}
      color="info.dark"
    />
  )
}

export default ConditionalStatsTileContent
