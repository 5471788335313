import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import ToggleSwitch from '@components/form/ToggleSwitch'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { useIsFrenchUser } from '@hooks/useIsFrenchUser'
import Paper from '@mui/material/Paper'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { VineRowStatsApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { RowStatsAttributeSet } from '@typings/dtos/vine-row-stats/row-stats-attribute-set.enum'
import { healthCols, healthColsGrouping } from '@utils/datagrid-cols/health-cols'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

const HealthReportingPage = () => {
  const [attributeSet, setAttributeSet] = useState<RowStatsAttributeSet>(RowStatsAttributeSet.Disease)

  const [selectedBlocks, startDate, endDate, blocksAsMap] = useVineyardStore((s) => [
    s.selectedBlocks,
    s.startDate,
    s.endDate,
    s.blocksAsMap,
  ])

  const handleAxiosError = useHandleAxiosError()
  const { localeText, t } = useDataGridLocaleText()
  const isFrenchUser = useIsFrenchUser()

  const { data, refetch, isFetching, dataUpdatedAt, error } = useQuery({
    queryKey: ['rowStatsMildewAndFD', attributeSet],
    initialData: [],
    queryFn: () =>
      VineRowStatsApi.getRowStatsByAttributeSet({
        blockIds: selectedBlocks.map((block) => block.id),
        dateStart: startDate,
        dateEnd: endDate,
        qualityFilter: true,
        attributeSet,
      }),
  })

  useEffect(() => {
    if (error) handleAxiosError(error)
  }, [error, handleAxiosError])

  useEffect(() => {
    if (!selectedBlocks.length) return
    refetch()
  }, [selectedBlocks, startDate, endDate, refetch])

  return (
    <Paper sx={{ borderRadius: 2, height: 'calc(100vh - 90px)', overflow: 'hidden', mt: 1 }} variant="outlined">
      <DataGridPro
        key={dataUpdatedAt}
        rows={data}
        columns={healthCols(blocksAsMap, t, isFrenchUser, attributeSet)}
        loading={isFetching}
        initialState={{ pinnedColumns: { left: ['scanDate', 'blockId', 'rowNumber'] } }}
        getRowId={(row) => row.vineRowId + row.scanDate}
        columnGroupingModel={healthColsGrouping(t)}
        disableColumnMenu
        hideFooter
        slots={{
          toolbar: () => (
            <CustomGridToolbar
              fileName={`Health Report - ${format(new Date(), 'dd MMM yyyy')}`}
              rowCount={data?.length}
              extensionComponent={
                isFrenchUser ? (
                  <div style={{ marginRight: 8 }}>
                    <ToggleSwitch
                      options={[
                        { label: 'mildew', value: RowStatsAttributeSet.Disease },
                        { label: 'flavescence_doree', value: RowStatsAttributeSet.FlavescenceDoree },
                      ]}
                      value={attributeSet}
                      onClick={(val) => setAttributeSet(val as RowStatsAttributeSet)}
                      optionWidth={150}
                    />
                  </div>
                ) : null
                // <div style={{ display: 'flex', gap: 12 }}>
                //   <button onClick={() => setAttributeSet(RowStatsAttributeSet.Disease)}>Mildew</button>
                //   <button onClick={() => setAttributeSet(RowStatsAttributeSet.FlavescenceDoree)}>FD</button>
                //   <pre>{JSON.stringify(attributeSet, null, 2)}</pre>
                // </div>
              }
            />
          ),
        }}
        // slotProps={{ toolbar: { fileName: `Health Report - ${format(new Date(), 'dd MMM yyyy')}`, rowCount: data?.length } }}
        localeText={localeText}
      />
    </Paper>
  )
}

export default HealthReportingPage
