import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import BoxplotChart from '@components/echarts/charts/BoxplotChart'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { budsPerVineSortOptions } from '@utils/options'
import { numberFmtThousandSeparator } from '@utils/utility-fns'
import { useEffect, useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'

type BowPrunedSectionProps = {
  data: PruningRowStatsAggregated[]
}

const BowPrunedSection = ({ data }: BowPrunedSectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [bowPrunedData, setBowPrunedData] = useState(data)
  const { t } = useTranslate()

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setBowPrunedData((prev) => [
        ...prev.sort((a, b) =>
          activeSort === 'blockName-asc'
            ? b['Block Name'].localeCompare(a['Block Name'])
            : a['Block Name'].localeCompare(b['Block Name'])
        ),
      ])
    }
    if (activeSort.startsWith('blockName')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      setBowPrunedData((prev) => {
        //text before the first hyphen
        const sortKey = activeSort.split('-')[0] as keyof PruningRowStatsAggregated
        return [
          ...prev.sort((a, b) => {
            const valueA = a[sortKey] as number
            const valueB = b[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        ]
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort, data])

  const totalVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Total Vines'], 0)
  const totalYoungVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Young Vines'], 0)
  const totalMissingVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Missing Vines'], 0)
  const totalNormalVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Normal Vines'], 0)

  return (
    <>
      <Box display="flex" gap={2} flexWrap="wrap">
        <HighlightsInfoWrapper
          label="showing_stats_for"
          mainHighlightComponent={<ConditionalStatsTileContent aggregatedData={data} />}
          tooltipInfo={'pruning_page.summary.tooltip_showing_stats_for'}
        />
        <HighlightsInfoWrapper
          label="vines_scanned"
          mainHighlightComponent={
            <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="total" color="info.dark" />
          }
        >
          <HighlightColumnWrapper>
            <HighlightInfo value={numberFmtThousandSeparator(totalYoungVines)} label="young_vines" />
            <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
          </HighlightColumnWrapper>
          <HighlightColumnWrapper divider>
            <HighlightInfo value={numberFmtThousandSeparator(totalNormalVines)} label="normal_vines" />
          </HighlightColumnWrapper>
        </HighlightsInfoWrapper>
      </Box>
      <SummaryTileWrapper
        label="calculated_buds_per_vine"
        width="100%"
        height={460}
        tooltipMsg="pruning_page.summary.bow.tooltip_calc_buds_per_vine"
        headerComponent={
          <SortBlocksMenu
            id="sort-canes-data"
            menuItems={budsPerVineSortOptions}
            value={activeSort}
            onClick={(val) => setActiveSort(val)}
            menuArrowPositionRight={110}
          />
        }
      >
        <Box py={1}>
          <BoxplotChart
            data={bowPrunedData.map((stat) => [
              stat['Bow Buds Per Vine Mean'],
              stat['Bow Buds Per Vine Mean'],
              stat['Bow Buds Per Vine Mean'],
              stat['Bow Buds Per Vine Mean'],
              stat['Bow Buds Per Vine Mean'],
            ])}
            categoryData={bowPrunedData.map((item) => item['Block Name'])}
            borderWidth={6}
            tooltipFormatter={(params) => {
              // @ts-expect-error - TS doesn't know that params is an object
              const blockName = params[0].name
              // @ts-expect-error - TS doesn't know that params is an object
              const [, , , mean, ,] = params[0].value
              return `<b>${blockName}</b><br />${t('mean')}: ${mean}`
            }}
          />
        </Box>
      </SummaryTileWrapper>
    </>
  )
}

export default BowPrunedSection
