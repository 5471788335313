import i18n from '@services/locales/i18n'
import { LoginResponseDto, UserSettingsDTO } from '@typings/dtos/user'
import { localStorageGetItem } from '@utils/utility-fns'
import { create } from 'zustand'

type AuthStore = {
  user: LoginResponseDto | null
  setUser: (user: LoginResponseDto | null) => void
  isAuthenticated: () => boolean
  logout: () => void
  isSidebarOpen: boolean
  setIsSidebarOpen: (isOpen: boolean) => void
  userSettings: UserSettingsDTO | null
  setUserSettings: (settings: UserSettingsDTO) => void
}

const useAuthStore = create<AuthStore>((set) => ({
  user: localStorageGetItem('user') ? JSON.parse(localStorageGetItem('user')!) : null,
  setUser: (user) => {
    localStorage.setItem('user', JSON.stringify(user))
    set({ user })
  },

  isAuthenticated: () => {
    const user = localStorageGetItem('user')
    if (user && user !== 'null' && JSON.parse(user).token) return true
    else return false
  },

  logout: () => {
    localStorage.removeItem('user')
    set({ user: null })
  },

  isSidebarOpen: true,
  setIsSidebarOpen: (isOpen) => set({ isSidebarOpen: isOpen }),

  userSettings: null,
  setUserSettings: (settings) => {
    i18n.changeLanguage(settings.language)
    set({ userSettings: settings })
  },
}))

export default useAuthStore
