import SpatialReference from '@arcgis/core/geometry/SpatialReference'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer'
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol'

export const vineyardLayer = new FeatureLayer({
  id: 'vineyard-layer',
  title: 'Vineyard Layer',
  source: [],
  fields: [
    {
      name: 'id',
      type: 'integer',
    },
    {
      name: 'name',
      type: 'string',
    },
  ],
  objectIdField: 'objectId',
  outFields: ['id', 'name'],
  geometryType: 'polygon',
  spatialReference: SpatialReference.WGS84,
  renderer: new SimpleRenderer({
    symbol: new SimpleFillSymbol({
      style: 'none',
      outline: {
        color: 'green',
        width: 2,
      },
    }),
  }),
  hasZ: true,
  visible: false,
})

export const vineyardHighlightLayer = new FeatureLayer({
  title: 'Vineyard Highlight Layer',
  source: [],
  fields: [
    {
      name: 'id',
      type: 'integer',
    },
    {
      name: 'name',
      type: 'string',
    },
  ],
  objectIdField: 'objectId',
  outFields: ['id', 'name'],
  geometryType: 'polygon',
  spatialReference: SpatialReference.WGS84,
  renderer: new SimpleRenderer({
    symbol: new SimpleFillSymbol({
      style: 'none',
      outline: {
        color: '#93DAED',
        width: 4,
      },
    }),
  }),
  hasZ: true,
})
