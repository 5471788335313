import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import LandBowSection from '@components/sections/land-summary/LandBowSection'
import LandCaneSection from '@components/sections/land-summary/LandCaneSection'
import LandSpurSection from '@components/sections/land-summary/LandSpurSection'
import { useGetRowStatsForCharts } from '@hooks/useGetRowStatsForCharts'
import useVineyardStore from '@stores/vineyard'
import { BlocksDataConditionEnum } from '@typings/component.enums'

const LandSummaryPage = () => {
  const { aggregatedData, selectedBlocks } = useGetRowStatsForCharts()
  const [activePruningStyle] = useVineyardStore((s) => [s.activePruningStyle])

  const { cane: caneData, spur: spurData, bow: bowData } = aggregatedData

  const pageCondition: Record<BlocksDataConditionEnum, boolean> = {
    [BlocksDataConditionEnum.NoBlock]: !selectedBlocks.length,
    [BlocksDataConditionEnum.OneBlock]: selectedBlocks.length === 1,
    [BlocksDataConditionEnum.BlocksButNoData]: !!selectedBlocks.length,
  }

  const condition = {
    cane: activePruningStyle === 'Cane',
    spur: activePruningStyle === 'Spur',
    bow: activePruningStyle === 'Bow',
  }

  return (
    <PruningStyleOptionsWrapper blocksCountByPruningStyle={{ Cane: caneData.length, Spur: spurData.length, Bow: bowData.length }}>
      {condition.cane && (
        <SummaryPageWrapper pageCondition={pageCondition} dataLength={caneData.length}>
          <LandCaneSection data={caneData} />
        </SummaryPageWrapper>
      )}
      {condition.spur && (
        <SummaryPageWrapper pageCondition={pageCondition} dataLength={spurData.length}>
          <LandSpurSection data={spurData} />
        </SummaryPageWrapper>
      )}
      {condition.bow && (
        <SummaryPageWrapper pageCondition={pageCondition} dataLength={bowData.length}>
          <LandBowSection data={bowData} />
        </SummaryPageWrapper>
      )}
    </PruningStyleOptionsWrapper>
  )
}

export default LandSummaryPage
