import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { ReactNode } from 'react'

type HighlightColumnWrapperProps = {
  children: ReactNode
  divider?: boolean
}

const HighlightColumnWrapper = ({ children, divider }: HighlightColumnWrapperProps) => {
  return (
    <>
      {divider && <Divider orientation="vertical" flexItem sx={{ borderWidth: 1, borderRadius: 12, opacity: 0.5 }} />}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={184}
        overflow="hidden"
        px={divider ? 1 : 0}
      >
        {children}
      </Box>
    </>
  )
}

export default HighlightColumnWrapper
