import Box from '@mui/material/Box'
import { default as MuiTextField, type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

export type TextFieldProps = MuiTextFieldProps & {
  length?: number
}

const TextField = ({ length, ...props }: TextFieldProps) => {
  return (
    <StyledTextField
      {...props}
      fullWidth
      InputLabelProps={{ shrink: true }}
      label={
        <>
          {props.label}
          {length && length !== 0 ? (
            <Box
              component="span"
              className="focusableBox"
              sx={{ bgcolor: 'grey', color: 'white', px: 0.7, ml: 1, borderRadius: 1 }}
            >
              {length}
            </Box>
          ) : null}
        </>
      }
      sx={{
        bgcolor: 'white',
        borderRadius: 1,
        label: { fontWeight: 500 },
        'label.Mui-focused': {
          color: 'primary.dark',
        },
        '.MuiOutlinedInput-root': {
          '.Mui-focused fieldset': {
            borderColor: 'primary.dark',
          },
        },
        ...props.sx,
      }}
    />
  )
}

export default TextField

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  '&:focus-within .focusableBox': {
    backgroundColor: theme.palette.primary.dark,
  },
}))
