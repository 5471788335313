import Iconify from '@components/data-display/Iconify'
import ToggleSwitch from '@components/form/ToggleSwitch'
import MenuBase from '@components/navigation/MenuBase'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { IOption } from '@typings/common'
import { useState } from 'react'

type SortBlocksMenuProps = {
  id: string
  value: string
  onClick: (value: string) => void
  menuItems: IOption<string>[]
  menuArrowPositionRight?: number
}

const SortBlocksMenu = ({ id, menuItems, value, onClick, menuArrowPositionRight }: SortBlocksMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { t } = useTranslate()

  const toggleOptions: [IOption<string>, IOption<string>] = [
    { label: 'asc', value: 'asc' },
    { label: 'desc', value: 'desc' },
  ]

  return (
    <>
      <Box
        role="button"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          px: 1.2,
          height: 20,
          borderRadius: 1,
          fontSize: 13,
          fontWeight: 500,
          gap: 1,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          bgcolor: 'info.dark',
          color: 'white',
          userSelect: 'none',
        }}
      >
        {t('sort')}
        <Iconify icon="mdi:sort" width={16} />
      </Box>
      <MenuBase
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        menuArrowPositionRight={menuArrowPositionRight}
        aria-hidden={!open}
      >
        <List disablePadding sx={{ userSelect: 'none' }}>
          {menuItems.map((menuItem) => (
            <ListItem key={menuItem.value} sx={{ py: 0.5 }}>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: 13, fontWeight: 500, pr: 1, color: 'info.dark' } }}>
                {t(menuItem.label)}
              </ListItemText>
              <ToggleSwitch
                options={
                  toggleOptions.map((item) => ({ ...item, value: menuItem.value + '-' + item.value })) as [
                    IOption<string>,
                    IOption<string>,
                  ]
                }
                value={value}
                onClick={(val) => onClick(val)}
                optionWidth={60}
              />
            </ListItem>
          ))}
        </List>
      </MenuBase>
    </>
  )
}

export default SortBlocksMenu
