import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import { SNACKBAR_ICON_SX } from '@services/theme/styles'
import { SnackbarProvider as SnackbarProviderNotIStack, closeSnackbar } from 'notistack'
import { ReactNode } from 'react'
import Iconify from './data-display/Iconify'

type SnackbarProviderProps = { children: ReactNode }

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  return (
    <StyledSnackbarProvider
      preventDuplicate
      variant="error"
      maxSnack={2}
      autoHideDuration={8000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={(key) => (
        <IconButton aria-label="delete" size="small" onClick={() => closeSnackbar(key)} color="inherit">
          <Iconify icon="material-symbols:close" />
        </IconButton>
      )}
      iconVariant={{ error: <Iconify icon="ooui:error" sx={SNACKBAR_ICON_SX} /> }}
    >
      {children}
    </StyledSnackbarProvider>
  )
}

export default SnackbarProvider

const StyledSnackbarProvider = styled(SnackbarProviderNotIStack)(() => ({
  fontSize: 15,
  //   '#notistack-snackbar': { alignItems: 'start' },
  //   '&.notistack-MuiContent-error': {
  //     backgroundColor: theme.palette.error.main ?? '#D32F2F',
  //     color: theme.palette.error.contrastText ?? '#FFFFFF',
  //   },
  //   '&.notistack-MuiContent-info': {
  //     backgroundColor: theme.palette.info.main ?? '#263238',
  //     color: theme.palette.info.contrastText ?? '#FFFFFF',
  //   },
  //   '&.notistack-MuiContent-success': {
  //     backgroundColor: theme.palette.success.main ?? '#37C49F',
  //     color: '#000000',
  //   },
  //   '&.notistack-MuiContent-warning': {
  //     backgroundColor: theme.palette.warning.main ?? '#FFA72E',
  //     color: '#000000',
  //   },
}))
