import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  layout: {
    margin: '18px 18px 46px 18px',
    color: '#334155',
    flex: 1,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#D4D4D4',
    marginVertical: 10,
  },
  footerLogo: {
    height: 20,
    width: 58,
    objectFit: 'contain',
    position: 'absolute',
    bottom: 10,
    right: 20,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 12,
    left: 20,
    textAlign: 'center',
    color: '#A8A29E',
  },
  tableCell: {
    height: 20,
    fontSize: 9,
    justifyContent: 'center',
    paddingLeft: 4,
  },
  tile: {
    width: 120,
    height: 60,
    padding: 6,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bar: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 8,
  },
  dateWrapper: {
    backgroundColor: '#334155',
    color: 'white',
    padding: '4px 8px',
    marginTop: 6,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

export const flexByAssumedCaneTarget = {
  4: 6,
  3: 5,
  2: 4,
  1: 3,
}
