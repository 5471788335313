import {
  BoxplotSeriesOption,
  EChartsOption,
  TooltipFormatterCallback,
  TopLevelFormatterParams,
} from 'echarts/types/dist/shared.js'
import EChartsWrapper from '../EChartsWrapper'

type BoxplotChartProps = {
  data: BoxplotSeriesOption['data']
  categoryData: string[] //Block names
  tooltipFormatter: string | TooltipFormatterCallback<TopLevelFormatterParams>
  borderWidth?: number
}

const BoxplotChart = ({ data, categoryData, tooltipFormatter, borderWidth = 2 }: BoxplotChartProps) => {
  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      formatter: tooltipFormatter,
    },
    dataZoom: [
      {
        type: 'slider',
        yAxisIndex: 0,
        width: 30,
        right: 30,
        start: 10,
        end: 100,
        handleSize: 34,
        showDetail: false,
        moveHandleSize: 12,
        moveHandleStyle: { color: '#000', opacity: 0.7 },
        dataBackground: {
          lineStyle: { color: '#000' },
          areaStyle: { color: '#000' },
        },
      },
      {
        type: 'inside',
        id: 'insideY',
        yAxisIndex: 0,
        start: 50,
        end: 100,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true,
      },
    ],
    grid: {
      top: '6%',
      left: '120px',
      right: '100px',
      bottom: '6%',
    },
    xAxis: {
      type: 'value',
      axisLine: { show: true },
      axisLabel: { show: true, fontWeight: 600 },
      splitLine: { show: true, lineStyle: { type: 'dashed', cap: 'butt', width: 2 } },
    },
    yAxis: {
      type: 'category',
      data: categoryData,
      axisLabel: {
        formatter: (value: string) => (value.length > 16 ? `${value.slice(0, 16)}..` : value),
        color: '#1E293B',
      },

      splitArea: {
        show: true,
        areaStyle: { color: ['#EFF6FF', '#F8FAFC'] },
      },
    },
    series: {
      name: 'Boxplot',
      type: 'boxplot',
      data: data,
      itemStyle: { color: '#DBEAFE', borderColor: '#818CF8', borderWidth },
    },
  }

  return <EChartsWrapper option={option} height={400} />
}

export default BoxplotChart
