import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro'
import { BlockDto } from '@typings/dtos/block'
import { RowStatsAttributeSet } from '@typings/dtos/vine-row-stats/row-stats-attribute-set.enum'
import { createFormatter } from '@utils/utility-fns'
import { TFunction } from 'i18next'

export const healthCols = (
  blocksAsMap: Map<number, BlockDto>,
  t: TFunction<'translation', undefined>,
  isFrenchUser: boolean,
  attributeSet: RowStatsAttributeSet
): GridColDef[] => {
  const sharedCols: GridColDef[] = [
    { field: 'scanDate', headerName: 'Date', valueGetter: (value) => new Date(value), type: 'date', width: 100 },
    { field: 'blockId', headerName: t('block'), width: 150, valueGetter: (value) => blocksAsMap.get(value)?.name },
    { field: 'rowNumber', headerName: t('row'), width: 70, type: 'number' },
  ]

  const mildewAndDamagedBunchCols: GridColDef[] = [
    {
      field: 'totalInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueGetter: (_, row) => row.stats['pm Total Infected Vines'],
      type: 'number',
      renderCell: ({ row }) => (
        <div>
          <span>{row.stats['pm Total Infected Vines']}</span>
          {row.stats['pm Total Infected Vines'] > 0 && (
            <>
              <span style={{ opacity: 0.6, fontWeight: 500, marginLeft: 20 }}>
                {row.stats['pm Total Percent Infected Vines'].toFixed(1)}%
              </span>
            </>
          )}
        </div>
      ),
      cellClassName: (params) => (params.row.stats['pm Total Infected Vines'] > 0 ? 'datagrid-cell-error' : ''),
      valueFormatter: createFormatter(),
    },
    {
      field: 'totalMaxInfections',
      headerName: t('max'),
      description: 'The number of infections on both leaves and bunches on the worst vine in the row',
      width: 90,
      valueGetter: (_, row) => row.stats['pm Total Max Infection'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'totalAverageInfection',
      headerName: t('average'),
      description: 'The average number of infected leaves and bunches per infected vine',
      width: 100,
      valueGetter: (_, row) => row.stats['pm Total Average Infection'].toFixed(1),
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'leafInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueGetter: (_, row) => row.stats['pm Leaf Infected Vines'],
      type: 'number',
      renderCell: ({ row }) => (
        <div>
          <span>{row.stats['pm Leaf Infected Vines']}</span>
          {row.stats['pm Leaf Infected Vines'] > 0 && (
            <>
              <span style={{ opacity: 0.6, fontWeight: 500, marginLeft: 20 }}>
                {row.stats['pm Leaf Percent Infected Vines'].toFixed(1)}%
              </span>
            </>
          )}
        </div>
      ),
      cellClassName: (params) => (params.row.stats['pm Leaf Infected Vines'] > 0 ? 'datagrid-cell-error' : ''),
      valueFormatter: createFormatter(),
    },
    {
      field: 'leafMaxInfections',
      headerName: t('max'),
      width: 90,
      valueGetter: (_, row) => row.stats['pm Leaf Max Infection'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'leafAverageInfection',
      headerName: t('average'),
      width: 100,
      valueGetter: (_, row) => row.stats['pm Leaf Average Infection'].toFixed(1),
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'bunchInfectedVines',
      headerName: t('infected_vines'),
      width: 140,
      valueGetter: (_, row) => row.stats['pm Bunch Infected Vines'],
      type: 'number',
      renderCell: ({ row }) => (
        <div>
          <span>{row.stats['pm Bunch Infected Vines']}</span>
          {row.stats['pm Bunch Infected Vines'] > 0 && (
            <>
              <span style={{ opacity: 0.6, fontWeight: 500, marginLeft: 20 }}>
                {row.stats['pm Bunch Percent Infected Vines'].toFixed(1)}%
              </span>
            </>
          )}
        </div>
      ),
      cellClassName: (params) => (params.row.stats['pm Bunch Infected Vines'] > 0 ? 'datagrid-cell-error' : ''),
      valueFormatter: createFormatter(),
    },
    {
      field: 'bunchMaxInfections',
      headerName: t('max'),
      width: 90,
      valueGetter: (_, row) => row.stats['pm Bunch Max Infection'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'bunchAverageInfection',
      headerName: t('average'),
      width: 100,
      valueGetter: (_, row) => row.stats['pm Bunch Average Infection'].toFixed(1),
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'damagedBunchInfectedVines',
      headerName: t('vines_count'),
      width: 140,
      sortComparator: (_v1, _v2, param1, param2) => {
        return param1.value - param2.value
      },
      valueGetter: (_, row) => row.stats['damaged Bunch Infected Vines'],
      type: 'number',
      renderCell: ({ row }) => (
        <div>
          <span>{row.stats['damaged Bunch Infected Vines']}</span>
          {row.stats['damaged Bunch Infected Vines'] > 0 && (
            <>
              <span style={{ opacity: 0.6, fontWeight: 500, marginLeft: 20 }}>
                {row.stats['damaged Bunch Percent Infected Vines'].toFixed(1)}%
              </span>
            </>
          )}
        </div>
      ),
      cellClassName: (params) => (params.row.stats['damaged Bunch Infected Vines'] > 0 ? 'datagrid-cell-error' : ''),
      valueFormatter: createFormatter(),
    },
    {
      field: 'damagedBunchMaxInfections',
      headerName: t('max'),
      width: 90,
      valueGetter: (_, row) => row.stats['damaged Bunch Max Infection'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'damagedBunchAverageInfection',
      headerName: t('average'),
      width: 100,
      valueGetter: (_, row) => row.stats['damaged Bunch Average Infection'].toFixed(1),
      type: 'number',
      valueFormatter: createFormatter(),
    },
  ]

  const nonFrenchCols: GridColDef[] = [
    // {
    //   field: 'botrytisInfectedVines',
    //   headerName: t('vines_count'),
    //   width: 140,
    //   sortComparator: (_v1, _v2, param1, param2) => {
    //     return param1.value - param2.value
    //   },
    //   valueGetter: (_, row) => row.stats['botrytis Bunch Rot Infected Vines'],
    //   type: 'number',
    //   renderCell: ({ row }) => (
    //     <div>
    //       <span>{row.stats['botrytis Bunch Rot Infected Vines']}</span>
    //       {row.stats['botrytis Bunch Rot Infected Vines'] > 0 && (
    //         <>
    //           <span style={{ opacity: 0.6, fontWeight: 500, marginLeft: 20 }}>
    //             {row.stats['botrytis Bunch Rot Infected Vines'].toFixed(1)}%
    //           </span>
    //         </>
    //       )}
    //     </div>
    //   ),
    //   cellClassName: (params) => (params.row.stats['botrytis Bunch Rot Infected Vines'] > 0 ? 'datagrid-cell-error' : ''),
    // },
    // {
    //   field: 'botrytisMaxInfections',
    //   headerName: t('max'),
    //   width: 90,
    //   valueGetter: (_, row) => row.stats['botrytis Bunch Rot Max Infection'],
    //   type: 'number',
    // },
    // {
    //   field: 'botrytisAverageInfection',
    //   headerName: t('average'),
    //   width: 100,
    //   valueGetter: (_, row) => row.stats['botrytis Bunch Rot Average Infection'].toFixed(1),
    //   type: 'number',
    // },
  ]

  const frenchCols: GridColDef[] = [
    {
      field: 'fdLowVines',
      headerName: t('low'),
      width: 90,
      valueGetter: (_, row) => row.stats['fd Low Infected Vines'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'fdMediumVines',
      headerName: t('medium'),
      width: 90,
      valueGetter: (_, row) => row.stats['fd Medium Infected Vines'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
    {
      field: 'fdHighVines',
      headerName: t('high'),
      width: 90,
      valueGetter: (_, row) => row.stats['fd High Infected Vines'],
      type: 'number',
      valueFormatter: createFormatter(),
    },
  ]

  if (isFrenchUser) {
    if (attributeSet === RowStatsAttributeSet.FlavescenceDoree) {
      return sharedCols.concat(frenchCols)
    } else {
      return sharedCols.concat(mildewAndDamagedBunchCols)
    }
  } else {
    return sharedCols.concat(mildewAndDamagedBunchCols, nonFrenchCols)
  }
}

export const healthColsGrouping = (t: TFunction<'translation', undefined>): GridColumnGroupingModel => [
  {
    groupId: 'mildewTotal',
    headerName: t('mildew_total'),
    description: 'Mildew Total',
    headerAlign: 'center',
    headerClassName: 'bg-lightblue-1',
    children: [{ field: 'totalInfectedVines' }, { field: 'totalMaxInfections' }, { field: 'totalAverageInfection' }],
  },
  {
    groupId: 'mildewLeaves',
    headerName: t('mildew_leaves'),
    headerAlign: 'center',
    description: 'Mildew on Leaves',
    headerClassName: 'bg-lightblue-2',
    children: [{ field: 'leafInfectedVines' }, { field: 'leafMaxInfections' }, { field: 'leafAverageInfection' }],
  },
  {
    groupId: 'mildewBunch',
    headerName: t('mildew_bunches'),
    headerAlign: 'center',
    description: 'Mildew on Bunches',
    headerClassName: 'bg-lightblue-1',
    children: [{ field: 'bunchInfectedVines' }, { field: 'bunchMaxInfections' }, { field: 'bunchAverageInfection' }],
  },
  {
    groupId: 'damagedBunch',
    headerName: t('damaged_bunches'),
    headerAlign: 'center',
    description: 'Damaged Bunches',
    headerClassName: 'bg-lightblue-2',
    children: [
      { field: 'damagedBunchInfectedVines' },
      { field: 'damagedBunchMaxInfections' },
      { field: 'damagedBunchAverageInfection' },
    ],
  },
  // {
  //   groupId: 'botrytis',
  //   headerName: t('botrytis'),
  //   headerAlign: 'center',
  //   description: 'Botrytis Bunch Rot',
  //   headerClassName: 'bg-lightblue-1',
  //   children: [{ field: 'botrytisInfectedVines' }, { field: 'botrytisMaxInfections' }, { field: 'botrytisAverageInfection' }],
  // },
  {
    groupId: 'fdVines',
    headerName: t('fd_vines'),
    headerAlign: 'center',
    description: 'Flavescence Dorée Infected Vines',
    headerClassName: 'bg-lightblue-1',
    children: [{ field: 'fdLowVines' }, { field: 'fdMediumVines' }, { field: 'fdHighVines' }],
  },
]
