import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useGetRowStatsPruningApi } from '@hooks/useGetRowStatsPruningApi'
import Paper from '@mui/material/Paper'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useVineyardStore from '@stores/vineyard'
import { PruningStyleUnion } from '@typings/dtos/block'
import { RowStatsPruningDto } from '@typings/dtos/vine-row-stats'
import { landCols } from '@utils/datagrid-cols/land-cols'
import { numberOfUniqueBlocks } from '@utils/utility-fns'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'

const LandReportingPage = () => {
  const [caneData, setCaneData] = useState<RowStatsPruningDto[]>([])
  const [spurData, setSpurData] = useState<RowStatsPruningDto[]>([])
  const [bowData, setBowData] = useState<RowStatsPruningDto[]>([])

  const [blocksCountByPruningStyle, setBlocksCountByPruningStyle] = useState<Record<PruningStyleUnion, number>>({
    Bow: 0,
    Cane: 0,
    Spur: 0,
  })

  const [blocksAsMap, canePrunedBlockIds, spurPrunedBlockIds, bowPrunedBlockIds, activePruningStyle] = useVineyardStore((s) => [
    s.blocksAsMap,
    s.canePrunedBlockIds,
    s.spurPrunedBlockIds,
    s.bowPrunedBlockIds,

    s.activePruningStyle,
  ])

  const { data, isFetching, dataUpdatedAt } = useGetRowStatsPruningApi()
  const { localeText, t } = useDataGridLocaleText()

  useEffect(() => {
    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = {
        Cane: canePrunedBlockIds,
        Spur: spurPrunedBlockIds,
        Bow: bowPrunedBlockIds,
      }[pruningStyle]

      return data?.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (data) {
      const bowData = filterDataByPruningStyle('Bow')
      const caneData = filterDataByPruningStyle('Cane')
      const spurData = filterDataByPruningStyle('Spur')

      setBowData(bowData)
      setCaneData(caneData)
      setSpurData(spurData)

      setBlocksCountByPruningStyle({
        Cane: numberOfUniqueBlocks(caneData),
        Spur: numberOfUniqueBlocks(spurData),
        Bow: numberOfUniqueBlocks(bowData),
      })
    }
  }, [data, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds])

  const dataByPruningStyle = { Bow: bowData, Cane: caneData, Spur: spurData }

  return (
    <PruningStyleOptionsWrapper blocksCountByPruningStyle={blocksCountByPruningStyle}>
      <Paper sx={{ borderRadius: 1, height: 'calc(100vh - 144px)', overflow: 'hidden' }} variant="outlined">
        <DataGridPro
          key={dataUpdatedAt}
          rows={dataByPruningStyle[activePruningStyle] || []}
          columns={landCols(blocksAsMap, activePruningStyle, t)}
          loading={isFetching}
          initialState={{ pinnedColumns: { left: ['scanDate', 'blockId', 'rowNumber'] } }}
          getRowId={(row) => row.vineRowId + row.scanDate}
          disableColumnMenu
          hideFooter
          slots={{
            toolbar: () => (
              <CustomGridToolbar
                fileName={`Land Report - ${format(new Date(), 'dd MMM yyyy')}`}
                rowCount={dataByPruningStyle[activePruningStyle].length}
              />
            ),
          }}
          // slotProps={{
          //   toolbar: {
          //     fileName: `Land Report - ${format(new Date(), 'dd MMM yyyy')}`,
          //     rowCount: dataByPruningStyle[activePruningStyle].length,
          //     dataGridStats: activePruningStyle === 'Bow' ? undefined : dataGridStats,
          //     onClickDataGridStats: () => setDataGridStats(dataGridStats === 'standard' ? 'expert' : 'standard'),
          //   },
          // }}
          localeText={localeText}
        />
      </Paper>
    </PruningStyleOptionsWrapper>
  )
}

export default LandReportingPage
