import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import SummaryPageWrapper from '@components/containers/SummaryPageWrapper'
import Iconify from '@components/data-display/Iconify'
import ToggleSwitch from '@components/form/ToggleSwitch'
import PruningReport from '@components/pdf/pruning-report/PruningReport'
import BowPrunedSection from '@components/sections/pruning-summary/BowPrunedSection'
import CanePrunedSection from '@components/sections/pruning-summary/CanePrunedSection'
import SpurPrunedSection from '@components/sections/pruning-summary/SpurPrunedSection'
import { useGetRowStatsForCharts } from '@hooks/useGetRowStatsForCharts'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { pdf } from '@react-pdf/renderer'
import useVineyardStore from '@stores/vineyard'
import { BlocksDataConditionEnum } from '@typings/component.enums'
import { localStorageGetItem } from '@utils/utility-fns'
import { saveAs } from 'file-saver'

import { useEffect, useState } from 'react'

const PruningSummaryPage = () => {
  const loadInitialState = () => {
    const savedState = localStorageGetItem('statsBy')
    return savedState === 'cane' ? 'cane' : 'vine'
  }
  const [statsBy, setStatsBy] = useState<'vine' | 'cane'>(loadInitialState)

  useEffect(() => {
    localStorage.setItem('statsBy', statsBy)
  }, [statsBy])

  const { t } = useTranslate()
  const { palette } = useTheme()
  const [activePruningStyle] = useVineyardStore((s) => [s.activePruningStyle])
  const { aggregatedData, selectedBlocks, data } = useGetRowStatsForCharts()

  const { cane: caneData, spur: spurData, bow: bowData } = aggregatedData

  const pageCondition: Record<BlocksDataConditionEnum, boolean> = {
    [BlocksDataConditionEnum.NoBlock]: !selectedBlocks.length,
    [BlocksDataConditionEnum.OneBlock]: selectedBlocks.length === 1,
    [BlocksDataConditionEnum.BlocksButNoData]: !!selectedBlocks.length,
  }

  const condition = {
    cane: activePruningStyle === 'Cane',
    spur: activePruningStyle === 'Spur',
    bow: activePruningStyle === 'Bow',
  }

  const downloadPdf = async () => {
    const fileName = 'Pruning Report.pdf'
    const blob = await pdf(<PruningReport aggregatedData={aggregatedData} rowStats={data} range={palette.range} />).toBlob()
    saveAs(blob, fileName)
  }

  return (
    <PruningStyleOptionsWrapper
      blocksCountByPruningStyle={{ Cane: caneData.length, Spur: spurData.length, Bow: bowData.length }}
      statsToggleComponent={
        condition.cane && caneData.length ? (
          <Box display="flex" gap={2} alignItems="end">
            <Box
              display="flex"
              bgcolor="primary.dark"
              color="grey.50"
              gap="2px"
              borderRadius={1}
              fontSize={12}
              fontWeight={500}
              alignItems="center"
              p="1px 7px 1px 4px"
              sx={{ cursor: 'pointer', ':hover': { bgcolor: 'primary.main' } }}
              onClick={downloadPdf}
            >
              <Iconify title="Download Pruning Report PDF" icon="ic:baseline-download" color="grey.50" width={15} />
              {t('pruning_report')}
            </Box>
            <ToggleSwitch
              options={[
                { label: 'stats_by_vine', value: 'vine' },
                { label: 'stats_by_cane', value: 'cane' },
              ]}
              value={statsBy}
              onClick={(val) => setStatsBy(val as 'vine' | 'cane')}
              optionWidth={120}
            />
          </Box>
        ) : null
      }
    >
      {condition.cane && (
        <SummaryPageWrapper pageCondition={pageCondition} dataLength={caneData.length}>
          <CanePrunedSection data={caneData} statsBy={statsBy} />
        </SummaryPageWrapper>
      )}
      {condition.spur && (
        <SummaryPageWrapper pageCondition={pageCondition} dataLength={spurData.length}>
          <SpurPrunedSection data={spurData} />
        </SummaryPageWrapper>
      )}
      {condition.bow && (
        <SummaryPageWrapper pageCondition={pageCondition} dataLength={bowData.length}>
          <BowPrunedSection data={bowData} />
        </SummaryPageWrapper>
      )}
    </PruningStyleOptionsWrapper>
  )
}

export default PruningSummaryPage
