import { Document } from '@react-pdf/renderer'
import { ColorRange } from '@typings/common'
import { RowStatsPruningDto } from '@typings/dtos/vine-row-stats'
import { aggregatedPruningRowStatsData } from '@utils/utility-fns'
import BlockDetailsPage from './BlockDetailsPage'
import TitlePage from './TitlePage'

type PruningReportProps = {
  aggregatedData: ReturnType<typeof aggregatedPruningRowStatsData>
  rowStats: RowStatsPruningDto[]
  range: ColorRange
}

const PruningReport = ({ aggregatedData, rowStats, range }: PruningReportProps) => {
  const { cane } = aggregatedData

  return (
    <Document>
      <TitlePage data={cane} />
      {cane.map((caneData) => (
        <BlockDetailsPage
          key={caneData['Block Id']}
          caneData={caneData}
          rowStats={rowStats.filter((row) => row.blockId === caneData['Block Id'])}
          range={range}
        />
      ))}
    </Document>
  )
}

export default PruningReport
