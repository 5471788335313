import Iconify from '@components/data-display/Iconify'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type FallbackMessageProps = {
  icon: string
  message: string
}

const FallbackMessage = ({ icon, message }: FallbackMessageProps) => {
  const { t } = useTranslate()

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="80dvh">
      <Box display="grid" alignItems="center" justifyContent="center" gap={2} maxWidth={500}>
        <Iconify icon={icon} width={40} mx="auto" color="info.dark" />
        <Typography sx={{ fontSize: 15, color: 'info.dark', fontWeight: 400, textAlign: 'center' }}>{t(message)}</Typography>
      </Box>
    </Box>
  )
}

export default FallbackMessage
