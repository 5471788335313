export const VISUAL_VARIABLES = [
  {
    type: 'size',
    valueExpression: '$view.scale',
    stops: [
      { size: 78, value: 71 },
      { size: 45, value: 141 },
      { size: 23, value: 282 },
      { size: 13, value: 564 },
      { size: 11, value: 1128 },
      { size: 9, value: 2256 },
      { size: 8, value: 4513 },
      { size: 6, value: 9027 },
    ],
  } as __esri.SizeVariableProperties,
]
