import ScanTimelineDrawer from '@components/sections/ScanTimelineDrawer'
import SettingsDrawer from '@components/sections/SettingsDrawer'
import Box from '@mui/material/Box'
import useAuthStore from '@stores/auth'
import { ReactNode, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'
import Sidebar from './Sidebar'

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const [openSettings, setOpenSettings] = useState(false)
  const [openScanTimeline, setOpenScanTimeline] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useAuthStore((s) => [s.isSidebarOpen, s.setIsSidebarOpen])

  const location = useLocation()

  const isSettingsPage = location.pathname.includes('/settings')

  return (
    <>
      <Header onClickSettings={() => setOpenSettings(true)} onClickTimelineIcon={() => setOpenScanTimeline(true)} />
      <Box display="flex" flexGrow={1} sx={{ overflowY: 'hidden' }}>
        {isSettingsPage ? (
          <Box width="100%">{children}</Box>
        ) : (
          <>
            <Sidebar onSidebarToggle={(state) => setIsSidebarOpen(!state)} />
            <Box component="main" flexGrow={1} display="flex" width="100%">
              <Box width={isSidebarOpen ? 320 : 50}></Box>
              <Box
                maxWidth={isSidebarOpen ? 'calc(100vw - 336px)' : '100vw'}
                width="100%"
                pl={4}
                pr={2}
                pt={2}
                sx={{ overflowX: 'auto' }}
              >
                {children}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <SettingsDrawer open={openSettings} onClose={() => setOpenSettings(false)} />
      <ScanTimelineDrawer open={openScanTimeline} onClose={() => setOpenScanTimeline(false)} />
    </>
  )
}

export default Layout
