import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import BoxplotChart from '@components/echarts/charts/BoxplotChart'
import ToggleSwitch from '@components/form/ToggleSwitch'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { Pruning_BudsSortOptions } from '@utils/options'
import { useEffect, useState } from 'react'

type SpurPruningVineStatsBoxplotChartProps = {
  data: PruningRowStatsAggregated[]
}

type StatsView = 'budsPerVine' | 'budsPerSpurPerVine' | 'spursPerVine'

const SpurPruningVineStatsBoxplotChart = ({ data }: SpurPruningVineStatsBoxplotChartProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [spurPruningVineStats, setSpurPruningVineStats] = useState<PruningRowStatsAggregated[]>([])
  const [activeBudsStatsView, setActiveBudsStatsView] = useState<StatsView>('budsPerVine')

  const { t } = useTranslate()

  const dataByActiveView: Record<StatsView, number[][]> = {
    budsPerVine: spurPruningVineStats.map((stat) => {
      return [
        stat['Buds Per Vine LQ'],
        stat['Buds Per Vine LQ'],
        stat['Buds Per Vine Mean'],
        stat['Buds Per Vine UQ'],
        stat['Buds Per Vine UQ'],
      ]
    }),
    budsPerSpurPerVine: spurPruningVineStats.map((stat) => {
      return [
        stat['Buds Per Spur Per Vine LQ'],
        stat['Buds Per Spur Per Vine LQ'],
        stat['Buds Per Spur Per Vine Mean'],
        stat['Buds Per Spur Per Vine UQ'],
        stat['Buds Per Spur Per Vine UQ'],
      ]
    }),
    spursPerVine: spurPruningVineStats.map((stat) => {
      return [
        stat['Spurs Per Vine LQ'],
        stat['Spurs Per Vine LQ'],
        stat['Spurs Per Vine Mean'],
        stat['Spurs Per Vine UQ'],
        stat['Spurs Per Vine UQ'],
      ]
    }),
  }

  useEffect(() => {
    setSpurPruningVineStats(data)
  }, [data])

  useEffect(() => {
    if (activeSort === '') return

    const sortDataByBlockName = () => {
      setSpurPruningVineStats((prev) => [
        ...prev.sort((a, b) =>
          activeSort === 'Block Name-asc'
            ? b['Block Name'].localeCompare(a['Block Name'])
            : a['Block Name'].localeCompare(b['Block Name'])
        ),
      ])
    }
    if (activeSort.startsWith('Block Name')) sortDataByBlockName()

    const sortDataByNumericalProperties = () => {
      const prefix = {
        budsPerVine: 'Buds Per Vine ',
        budsPerSpurPerVine: 'Buds Per Spur Per Vine ',
        spursPerVine: 'Spurs Per Vine ',
      }

      setSpurPruningVineStats((prev) => {
        //text before the first hyphen
        const sortKey = (prefix[activeBudsStatsView] + activeSort.split('-')[0]) as keyof PruningRowStatsAggregated
        return [
          ...prev.sort((a, b) => {
            const valueA = a[sortKey] as number
            const valueB = b[sortKey] as number
            return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
          }),
        ]
      })
    }
    return sortDataByNumericalProperties()
  }, [activeSort, activeBudsStatsView, data])

  return (
    <Box pb={4}>
      <SummaryTileWrapper
        label="vine_stats"
        width="100%"
        height={500}
        tooltipMsg={'pruning_page.summary.spur.tooltip_vine_stats'}
        headerComponent={
          <>
            <ToggleSwitch
              options={[
                { label: 'buds_per_vine', value: 'budsPerVine' },
                { label: 'buds_per_spur_per_vine', value: 'budsPerSpurPerVine' },
                { label: 'spurs_per_vine', value: 'spursPerVine' },
              ]}
              value={activeBudsStatsView}
              onClick={(val) => setActiveBudsStatsView(val as 'budsPerVine' | 'budsPerSpurPerVine' | 'spursPerVine')}
              optionWidth={200}
            />
            <SortBlocksMenu
              id="sort-vine-stats-data"
              menuItems={Pruning_BudsSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          </>
        }
      >
        <Box py={4}>
          <BoxplotChart
            data={dataByActiveView[activeBudsStatsView]}
            categoryData={spurPruningVineStats.map((stat) => stat['Block Name'])}
            tooltipFormatter={(params) => {
              // @ts-expect-error - TS doesn't know that params is an object
              const blockName = params[0].name
              // @ts-expect-error - TS doesn't know that params is an object
              const [, , lq, mean, uq] = params[0].value
              return `<b>${blockName}</b><br />${t('lower_quartile')}: ${lq}<br />${t('mean')}: ${mean}<br />${t('upper_quartile')}: ${uq}`
            }}
          />
        </Box>
      </SummaryTileWrapper>
    </Box>
  )
}

export default SpurPruningVineStatsBoxplotChart
