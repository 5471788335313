import { VineyardApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetVineyardsApi() {
  const [setVineyards] = useVineyardStore(useShallow((s) => [s.setVineyards]))
  const handleAxiosError = useHandleAxiosError()

  const {
    data,
    error,
    isError,
    isLoading: isLoadingVineyards,
    isFetching: isFetchingVineyards,
  } = useQuery({ queryKey: ['vineyards'], queryFn: () => VineyardApi.getVineyards(), initialData: [] })

  useEffect(() => {
    if (data) setVineyards(data)
  }, [data, setVineyards])

  useEffect(() => {
    if (isError) handleAxiosError(error)
  }, [error, isError, handleAxiosError])

  return { isLoadingVineyards, isFetchingVineyards }
}
