import Iconify from '@components/data-display/Iconify'
import { useTranslate } from '@hooks/useLocales'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import HighlightColumnWrapper from './HighlightColumnWrapper'

type HighlightsInfoWrapperProps = {
  label: string
  mainHighlightComponent: ReactNode
  children?: ReactNode
  tooltipInfo?: string
}

const HighlightsInfoWrapper = ({ label, children, mainHighlightComponent, tooltipInfo }: HighlightsInfoWrapperProps) => {
  const { t } = useTranslate()

  return (
    <Paper
      variant="outlined"
      sx={{
        position: 'relative',
        height: 150,
        width: 'fit-content',
        p: 1.6,
        display: 'flex',
        borderRadius: 1.6,
        bgcolor: '#FAFAFA',
        borderColor: '#CBD5E1',
      }}
    >
      <HighlightColumnWrapper>
        <Typography
          component="label"
          fontSize={13}
          fontWeight={600}
          color="info.dark"
          width="fit-content"
          lineHeight={1}
          pr={2}
          textTransform="uppercase"
        >
          {t(label)}
        </Typography>
        {mainHighlightComponent}
      </HighlightColumnWrapper>
      {children}
      {tooltipInfo && (
        <Tooltip arrow title={t(tooltipInfo)}>
          <Iconify icon="ooui:info-filled" color="grey.500" width={17} sx={{ position: 'absolute', right: 10, top: 10 }} />
        </Tooltip>
      )}
    </Paper>
  )
}

export default HighlightsInfoWrapper
