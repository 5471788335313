import CustomGridToolbar from '@components/datagrid/CustomGridToolbar'
import { useGetRowStatsPruningApi } from '@hooks/useGetRowStatsPruningApi'
import Paper from '@mui/material/Paper'

import PruningStyleOptionsWrapper from '@components/containers/PruningStyleOptionsWrapper'
import PruningReport from '@components/pdf/pruning-report/PruningReport'
import useDataGridLocaleText from '@hooks/useDataGridLocaleText'
import { useTheme } from '@mui/material/styles'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { pdf } from '@react-pdf/renderer'
import useVineyardStore from '@stores/vineyard'
import { PruningStyleUnion } from '@typings/dtos/block'
import { RowStatsPruningDto } from '@typings/dtos/vine-row-stats'
import { pruningCols, pruningColsGrouping } from '@utils/datagrid-cols/pruning-cols'
import { aggregatedPruningRowStatsData, numberOfUniqueBlocks } from '@utils/utility-fns'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'

const PruningReportingPage = () => {
  const [bowData, setBowData] = useState<RowStatsPruningDto[]>([])
  const [caneData, setCaneData] = useState<RowStatsPruningDto[]>([])
  const [spurData, setSpurData] = useState<RowStatsPruningDto[]>([])
  const [blocksCountByPruningStyle, setBlocksCountByPruningStyle] = useState<Record<PruningStyleUnion, number>>({
    Bow: 0,
    Cane: 0,
    Spur: 0,
  })
  const [dataGridStats, setDataGridStats] = useState<'standard' | 'expert'>('standard')

  const [blocksAsMap, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds, activePruningStyle] = useVineyardStore((s) => [
    s.blocksAsMap,
    s.bowPrunedBlockIds,
    s.canePrunedBlockIds,
    s.spurPrunedBlockIds,
    s.activePruningStyle,
  ])

  const { palette } = useTheme()
  const { data, isFetching, dataUpdatedAt } = useGetRowStatsPruningApi()
  const { localeText, t } = useDataGridLocaleText()

  //TODO: Currently this logic is repeated inside summary and reporting pages,
  // Needs to be refactored to a single place
  useEffect(() => {
    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = {
        Bow: bowPrunedBlockIds,
        Cane: canePrunedBlockIds,
        Spur: spurPrunedBlockIds,
      }[pruningStyle]

      return data?.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (data) {
      const bowData = filterDataByPruningStyle('Bow')
      const caneData = filterDataByPruningStyle('Cane')
      const spurData = filterDataByPruningStyle('Spur')

      setBowData(bowData)
      setCaneData(caneData)
      setSpurData(spurData)

      setBlocksCountByPruningStyle({
        Bow: numberOfUniqueBlocks(bowData),
        Cane: numberOfUniqueBlocks(caneData),
        Spur: numberOfUniqueBlocks(spurData),
      })
    }
  }, [data, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds])

  const dataByPruningStyle = { Bow: bowData, Cane: caneData, Spur: spurData }

  const columnsByDataGridStats = {
    //remove all columns with header names "Lower Quartile", "Upper Quartile" and "Median"
    standard: pruningCols(blocksAsMap, activePruningStyle, t).filter(
      (col) => ![t('lower_quartile'), t('upper_quartile'), t('median')].includes(col.headerName as string)
    ),
    expert: pruningCols(blocksAsMap, activePruningStyle, t),
  }

  //TODO: Currently this logic is repeated inside summary and reporting pages,
  // Needs to be refactored to a single place
  useEffect(() => {
    const filterDataByPruningStyle = (pruningStyle: PruningStyleUnion) => {
      const blockIds = {
        Bow: bowPrunedBlockIds,
        Cane: canePrunedBlockIds,
        Spur: spurPrunedBlockIds,
      }[pruningStyle]

      return data?.filter((row) => blockIds.includes(row.blockId)) || []
    }

    if (data) {
      const bowData = filterDataByPruningStyle('Bow')
      const caneData = filterDataByPruningStyle('Cane')
      const spurData = filterDataByPruningStyle('Spur')

      setBowData(bowData)
      setCaneData(caneData)
      setSpurData(spurData)

      setBlocksCountByPruningStyle({
        Bow: numberOfUniqueBlocks(bowData),
        Cane: numberOfUniqueBlocks(caneData),
        Spur: numberOfUniqueBlocks(spurData),
      })
    }
  }, [data, bowPrunedBlockIds, canePrunedBlockIds, spurPrunedBlockIds])

  const downloadPdf = async () => {
    const aggregatedData = aggregatedPruningRowStatsData(
      data,
      blocksAsMap,
      canePrunedBlockIds,
      spurPrunedBlockIds,
      bowPrunedBlockIds
    )
    const fileName = 'Pruning Report.pdf'
    const blob = await pdf(<PruningReport aggregatedData={aggregatedData} rowStats={data} range={palette.range} />).toBlob()
    saveAs(blob, fileName)
  }

  return (
    <PruningStyleOptionsWrapper blocksCountByPruningStyle={blocksCountByPruningStyle}>
      <Paper sx={{ borderRadius: 1, height: 'calc(100vh - 144px)', overflow: 'hidden' }} variant="outlined">
        <DataGridPro
          key={dataUpdatedAt}
          rows={dataByPruningStyle[activePruningStyle] || []}
          columns={columnsByDataGridStats[dataGridStats]}
          loading={isFetching}
          initialState={{ pinnedColumns: { left: ['scanDate', 'blockId', 'rowNumber'] } }}
          getRowId={(row) => row.vineRowId + row.scanDate}
          columnGroupingModel={pruningColsGrouping(t)}
          disableColumnMenu
          hideFooter
          slots={{
            toolbar: () => (
              <CustomGridToolbar
                fileName={`Pruning Report - ${format(new Date(), 'dd MMM yyyy')}`}
                rowCount={dataByPruningStyle[activePruningStyle].length}
                dataGridStats={activePruningStyle === 'Bow' ? undefined : dataGridStats}
                onClickDataGridStats={() => setDataGridStats(dataGridStats === 'standard' ? 'expert' : 'standard')}
                downloadPdf={downloadPdf}
              />
            ),
          }}
          localeText={localeText}
        />
      </Paper>
    </PruningStyleOptionsWrapper>
  )
}

export default PruningReportingPage
