import useAuthStore from '@stores/auth'
import { useShallow } from 'zustand/react/shallow'

export function useIsFrenchUser() {
  const [user] = useAuthStore(useShallow((s) => [s.user]))

  if (!user) return false
  if (user.countryCode === 'FR') return true
  else return false
}
