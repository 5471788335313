import { GridColDef } from '@mui/x-data-grid-pro'
import { BlockDto } from '@typings/dtos/block'
import { TFunction } from 'i18next'

export const yieldCols = (blocksAsMap: Map<number, BlockDto>, t: TFunction<'translation', undefined>): GridColDef[] => [
  { field: 'scanDate', headerName: 'Date', valueGetter: (value) => new Date(value), type: 'date', width: 100 },
  { field: 'blockId', headerName: t('block'), width: 150, valueGetter: (value) => blocksAsMap.get(value)?.name },
  { field: 'rowNumber', headerName: t('row'), width: 70, type: 'number' },
  {
    field: 'bunchesPerVineMean',
    headerName: t('bunches_per_vine_visible_mean'),
    width: 260,
    valueGetter: (_, row) => row.stats['bunches Per Vine Mean'].toFixed(1),
    type: 'number',
  },
  {
    field: 'bunchesPerVineMedian',
    headerName: t('bunches_per_vine_visible_median'),
    width: 260,
    valueGetter: (_, row) => row.stats['bunches Per Vine Median'].toFixed(1),
    type: 'number',
  },
]
