export enum ToggleCompareBlockEnum {
  Pruning = 'pruning',
  Canes = 'canes',
}

export enum BlocksDataConditionEnum {
  NoBlock = 'NoBlock',
  OneBlock = 'OneBlock',
  BlocksButNoData = 'BlocksButNoData',
}
