import { colorBlind, standard } from '@services/theme/palette'
import { NavLinkItem, SettingToggleIconItem } from '@typings/component'

export const healthNavLinks: NavLinkItem[] = [
  { name: 'map_&_evidence', path: '/map', icon: 'material-symbols:map' },
  { name: 'reporting', path: '/reporting', icon: 'material-symbols:assignment-outline' },
]

export const yieldNavLinks: NavLinkItem[] = [
  { name: 'reporting', path: '/reporting', icon: 'material-symbols:assignment-outline' },
]

export const pruningNavLinks: NavLinkItem[] = [
  { name: 'summary', path: '/summary', icon: 'dashicons:analytics' },
  { name: 'reporting', path: '/reporting', icon: 'material-symbols:assignment-outline' },
]

export const landNavLinks: NavLinkItem[] = [
  { name: 'summary', path: '/summary', icon: 'dashicons:analytics' },
  { name: 'reporting', path: '/reporting', icon: 'material-symbols:assignment-outline' },
]

export const themeOptions: SettingToggleIconItem[] = [
  {
    label: 'Standard',
    value: 'standard',
    colors: standard.range,
  },
  {
    label: 'Color Blind',
    value: 'color-blind',
    colors: colorBlind.range,
  },
]
