import { useTranslate } from '@hooks/useLocales'
import Box, { type BoxProps } from '@mui/material/Box'
import { IOption } from '@typings/common'

export type ToggleSwitchProps = {
  options: IOption<string>[]
  value: string
  onClick: (value: string) => void
  optionWidth?: number
}

const ToggleSwitch = ({ value, options, onClick, optionWidth = 80 }: ToggleSwitchProps) => {
  const { t } = useTranslate()

  const sharedProps: BoxProps = {
    top: 0,
    width: optionWidth,
    height: 20,
    zIndex: 1,
    borderRadius: 1,
    m: 'auto',
    role: 'button',
    textAlign: 'center',
    style: { transition: 'all 0.3s ease-in-out' },
  }

  const indexOfValue = options.findIndex((x) => x.value === value)

  return (
    <Box
      boxShadow="inset 0 0 5px grey"
      width={optionWidth * options.length}
      position="relative"
      height={20}
      fontSize={13}
      borderRadius={1}
      fontWeight={500}
      sx={{ cursor: 'pointer', userSelect: 'none' }}
    >
      <Box display="flex" position="absolute">
        {options.map((option) => (
          <Box
            key={option.value}
            width={optionWidth}
            color={value === option.value ? 'white' : 'info.dark'}
            onClick={() => onClick(option.value)}
            lineHeight={1.7}
            {...sharedProps}
          >
            {t(option.label)}
          </Box>
        ))}
        {value && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width={optionWidth}
            height={20}
            borderRadius={1}
            zIndex={0}
            bgcolor={options.some((x) => x.value === value) ? 'info.dark' : ''}
            style={{
              transform: indexOfValue > 0 ? 'translateX(' + indexOfValue * optionWidth + 'px)' : 'translateX(0px)',
              transition: 'all 0.3s ease-in-out',
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default ToggleSwitch
