import PasswordTextField from '@components/form/PasswordTextField'
import { useHandleAxiosError } from '@hooks/useHandleAxiosError'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { UserApi } from '@services/api'
import useAuthStore from '@stores/auth'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { FormEvent, useState } from 'react'

const SettingsPage = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [user] = useAuthStore((s) => [s.user])
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslate()
  const handleAxiosError = useHandleAxiosError()

  const { mutate, isPending } = useMutation({
    mutationKey: ['changePassword'],
    mutationFn: () => UserApi.changePassword({ email: user?.email || '', password: currentPassword, newPassword }),
    onSuccess: () => {
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
      enqueueSnackbar('Password changed successfully', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      // @ts-expect-error:next-line
      handleAxiosError(error, error.response?.data?.message || 'Password change failed')
    },
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutate()
  }

  return (
    <Paper variant="outlined" sx={{ maxWidth: 'md', mx: 'auto', my: 4, bgcolor: 'grey.50' }}>
      <Typography variant="h6" color="grey.800" p={2}>
        {t('user_settings')}
      </Typography>
      <Divider />
      <Box component="form" onSubmit={handleSubmit} display="grid" p={2} maxWidth={400} gap={3} py={4}>
        <PasswordTextField
          required
          label={t('current_password')}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <PasswordTextField
          required
          label={t('new_password')}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <PasswordTextField
          required
          label={t('confirm_new_password')}
          value={confirmPassword}
          error={confirmPassword !== newPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={isPending || newPassword !== confirmPassword || !newPassword}
        >
          {t('change_password')}
        </Button>
      </Box>
    </Paper>
  )
}

export default SettingsPage
