import useVineyardStore from '@stores/vineyard'
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@utils/constants'
import { formatISOWithCustomHours, getBlocksFromScanDates } from '@utils/utility-fns'
import { subDays, subMonths } from 'date-fns'
import { useCallback, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

export function useHandleToggleScanRecency() {
  const [toggleScanRecency, setToggleScanRecency] = useState('')

  const [
    vineyards,
    varieties,
    blocks,
    setSelectedVineyardsLocal,
    setSelectedVarietiesLocal,
    setSelectedBlocksLocal,
    setStartDateLocal,
    setEndDateLocal,
  ] = useVineyardStore(
    useShallow((s) => [
      s.vineyards,
      s.varieties,
      s.blocks,
      s.setSelectedVineyardsLocal,
      s.setSelectedVarietiesLocal,
      s.setSelectedBlocksLocal,
      s.setStartDateLocal,
      s.setEndDateLocal,
    ])
  )

  const addBlocksVarietiesAndVineyards = useCallback(
    (startDate: Date, endDate: Date) => {
      setStartDateLocal(formatISOWithCustomHours(startDate))
      setEndDateLocal(formatISOWithCustomHours(endDate, { h: 23, m: 59, s: 59, ms: 999 }))
      const _blocks = getBlocksFromScanDates(blocks || [], startDate, endDate)
      const _varieties = varieties.filter((variety) => _blocks.some((block) => block.varietyId === variety.id))
      const _vineyards = vineyards.filter((vineyard) => _blocks.some((block) => block.vineyardId === vineyard.id))
      setSelectedBlocksLocal(_blocks)
      setSelectedVarietiesLocal(_varieties)
      setSelectedVineyardsLocal(_vineyards)
    },
    [
      blocks,
      setEndDateLocal,
      setSelectedBlocksLocal,
      setSelectedVarietiesLocal,
      setSelectedVineyardsLocal,
      setStartDateLocal,
      varieties,
      vineyards,
    ]
  )

  const handleToggleScanRecency = (value: '15' | '30' | '30+' | '') => {
    setToggleScanRecency(value)

    if (value === null || value === '') {
      setStartDateLocal(DEFAULT_START_DATE)
      setEndDateLocal(DEFAULT_END_DATE)
      setSelectedBlocksLocal([])
      setSelectedVarietiesLocal([])
      setSelectedVineyardsLocal([])
      return
    } else if (value === '15') addBlocksVarietiesAndVineyards(subDays(new Date(), 15), new Date())
    else if (value === '30') addBlocksVarietiesAndVineyards(subDays(new Date(), 30), new Date())
    else if (value === '30+') addBlocksVarietiesAndVineyards(subMonths(new Date(), 6), subDays(new Date(), 30))
  }

  return {
    toggleScanRecency,
    setToggleScanRecency,
    handleToggleScanRecency,
  }
}
