import { useTheme } from '@mui/material/styles'
import { PieSeriesOption } from 'echarts/charts'
import { EChartsOption, TooltipFormatterCallback, TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import EChartsWrapper from '../EChartsWrapper'

type PieChartProps = {
  data: PieSeriesOption['data']
  tooltipFormatter: string | TooltipFormatterCallback<TopLevelFormatterParams>
  height?: number | string
  color?: EChartsOption['color']
  legendFormatter?: (value: string) => string
}

const PieChart = ({ data, height = 112, tooltipFormatter, color, legendFormatter }: PieChartProps) => {
  const { palette } = useTheme()

  if (!color) color = [palette.range.posHigh, palette.range.negHigh]

  const option: EChartsOption = {
    color: color,
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormatter,
    },
    legend: {
      left: 0,
      align: 'left',
      orient: 'vertical',
      top: 10,
      formatter: legendFormatter,
    },
    animation: false,
    series: {
      data: data,
      type: 'pie',
      left: 150,
      bottom: 6,
      radius: '100%',
      top: 0,
      labelLine: { show: false },
      labelLayout: { hideOverlap: true },
      label: {
        position: 'inner',
        fontSize: 12,
        fontWeight: 600,
        formatter: (v) => `${v.percent?.toFixed(1)}%`,
        fontFamily: 'Roboto',
      },
      emphasis: { scale: false },
    },
  }

  return <EChartsWrapper option={option} height={height} />
}

export default PieChart
