import CssBaseline from '@mui/material/CssBaseline'
import { tablePaginationClasses } from '@mui/material/TablePagination'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import useAuthStore from '@stores/auth'
import { ReactNode, useMemo } from 'react'
import { info, palette } from './palette'
import { typography } from './typography'

type ThemeProviderProps = {
  children: ReactNode
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const [userSettings] = useAuthStore((s) => [s.userSettings])

  const mode = userSettings?.theme || 'standard'

  const memoizedValue = useMemo(
    () => ({
      palette: { ...palette(mode) },
      typography,
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: { borderRadius: 0, borderWidth: 0, [`& .${tablePaginationClasses.root}`]: { borderTop: 0 } },
            selectedRowCount: { whiteSpace: 'nowrap' },
            paper: { padding: 0 },
            columnHeaders: { borderRadius: 0, backgroundColor: '#F2F2F7' },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: { fontSize: 12, backgroundColor: info.dark, color: 'white' },
            arrow: { color: info.dark },
          },
        },
      },
    }),
    [userSettings?.theme]
  )

  const theme = createTheme(memoizedValue)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
