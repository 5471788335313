import Iconify from '@components/data-display/Iconify'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

type DrawerHeadProps = {
  label: string
  onClose: VoidFunction
  icon: string
  width?: number
}

const DrawerHead = ({ label, onClose, icon, width = 24 }: DrawerHeadProps) => {
  return (
    <div>
      <Box display="flex" p={2} alignItems="center" height={70}>
        <Iconify icon={icon} color="info.dark" width={width} mr="10px" />
        <Typography fontSize={16} fontWeight={500} sx={{ flexGrow: 1, color: 'info.dark', mt: '2px' }}>
          {label}
        </Typography>
        <IconButton onClick={onClose}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </Box>
      <Divider sx={{ borderStyle: 'dashed' }} />
    </div>
  )
}

export default DrawerHead
