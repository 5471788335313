import Iconify from '@components/data-display/Iconify'
import ColorGridIcon from '@components/icons/ColorGridIcon'
import Box from '@mui/material/Box'
import { blue } from '@mui/material/colors'
import { ThemeMode } from '@typings/common'
import { SettingToggleIconItem } from '@typings/component'

type ToggleIconButtonGroupProps = {
  options: SettingToggleIconItem[]
  selectedValue: string | undefined
  onClick: (value: string | ThemeMode) => void
}

const ToggleIconButtonGroup = ({ options, selectedValue, onClick }: ToggleIconButtonGroupProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
      {options.map((o) => (
        <Box
          key={o.value}
          title={o.label}
          sx={{
            pt: 1,
            px: 2,
            borderRadius: 1,
            cursor: 'pointer',
            border: '1px solid',
            color: selectedValue === o.value ? 'grey.800' : 'grey.600',
            backgroundColor: selectedValue === o.value ? blue[50] : 'grey.100',
            borderColor: selectedValue === o.value ? blue[100] : 'grey.200',
            '&:hover': { backgroundColor: selectedValue === o.value ? blue[100] : 'grey.200' },
          }}
          onClick={() => onClick(o.value)}
        >
          {o.icon && <Iconify icon={o.icon} width={30} />}
          {o.colors && (
            <div>
              <div style={{ height: 40, paddingBottom: 8 }}>
                <ColorGridIcon colors={o.colors} />
              </div>
            </div>
          )}
        </Box>
      ))}
    </Box>
  )
}

export default ToggleIconButtonGroup
