import HighlightColumnWrapper from '@components/containers/HighlightColumnWrapper'
import HighlightsInfoWrapper from '@components/containers/HighlightsInfoWrapper'
import SummaryTileWrapper from '@components/containers/SummaryTileWrapper'
import HighlightInfo from '@components/data-display/HighlightInfo'
import SortBlocksMenu from '@components/echarts/SortBlocksMenu'
import PieChart from '@components/echarts/charts/PieChart'
import StackedHorizontalBar from '@components/echarts/charts/StackedHorizontalBar'
import { useTranslate } from '@hooks/useLocales'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { ColorRange } from '@typings/common'
import { BlockNamesWithSameValue } from '@typings/component'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { DEFAULT_BLOCK_NAMES_WITH_SAME_VAL } from '@utils/constants'
import { blockPotentialSortOptions, canesSortOptions } from '@utils/options'
import {
  colorByAssumedCaneTarget,
  createCanesDataset,
  findHighestValueBlockNames,
  findLowestValueBlockNames,
  numberFmtThousandSeparator,
  rowStatsPruningToEChartsData,
} from '@utils/utility-fns'
import { TopLevelFormatterParams } from 'echarts/types/dist/shared.js'
import i18next from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import ConditionalStatsTileContent from '../ConditionalStatsTileContent'
import CanePruningBudsStatsBoxplotChart from './charts/CanePruningBudsStatsBoxplotChart'

type CanePrunedSectionProps = {
  data: PruningRowStatsAggregated[]
  statsBy: 'cane' | 'vine'
}

const CanePrunedSection = ({ data, statsBy }: CanePrunedSectionProps) => {
  const [activeSort, setActiveSort] = useState('')
  const [maxProductive, setMaxProductive] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [leastProductive, setLeastProductive] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [maxPrunedToTarget, setMaxPrunedToTarget] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [maxNotPrunedToTarget, setNotMaxPrunedToTarget] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [highestBlockPotential, setHighestBlockPotential] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [lowestBlockPotential, setLowestBlockPotential] = useState<BlockNamesWithSameValue>(DEFAULT_BLOCK_NAMES_WITH_SAME_VAL)
  const [canesDataset, setCanesDataset] = useState<ReturnType<typeof createCanesDataset>>({
    id: 'canes-dataset',
    dimensions: ['blockCode'],
    source: [],
  })

  const { t } = useTranslate()
  const { palette } = useTheme()

  const totalDeadVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Dead Vines'], 0)
  const totalMissingVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Missing Vines'], 0)
  const totalVines = data.reduce((acc: number, curr: PruningRowStatsAggregated) => acc + curr['Total Vines'], 0)

  const sortDatasetByName = useCallback(() => {
    setCanesDataset({
      ...canesDataset,
      // @ts-expect-error - TS can't access source type definition
      source: canesDataset.source.sort((a, b) => {
        return activeSort === 'blockName-asc' ? b.blockCode.localeCompare(a.blockCode) : a.blockCode.localeCompare(b.blockCode)
      }),
    })
  }, [activeSort, canesDataset])

  const sortDatasetByCanesValue = useCallback(() => {
    const sortKey = activeSort.split('-')[0] as keyof typeof createCanesDataset

    if (sortKey) {
      setCanesDataset({
        ...canesDataset,
        // @ts-expect-error - TS can't access source type definition
        source: canesDataset.source.sort((a, b) => {
          const valueA = a[sortKey] || 0
          const valueB = b[sortKey] || 0
          return activeSort.endsWith('-asc') ? valueB - valueA : valueA - valueB
        }),
      })
    }
  }, [activeSort, canesDataset])

  const isStatsByVine = statsBy === 'vine'
  const isStatsByCane = statsBy === 'cane'

  useEffect(() => {
    if (activeSort === '') return
    if (activeSort.startsWith('blockName')) sortDatasetByName()
    else sortDatasetByCanesValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSort, canesDataset.source])

  /* Creating data for the charts, based on data from API (custom hook) */
  useEffect(() => {
    setMaxProductive(findHighestValueBlockNames(data, 'Productive%'))
    setLeastProductive(findLowestValueBlockNames(data, 'Productive%'))
    setCanesDataset(createCanesDataset(data, statsBy))

    if (isStatsByVine) {
      setMaxPrunedToTarget(findHighestValueBlockNames(data, 'Pruned To Target%'))
      setNotMaxPrunedToTarget(findHighestValueBlockNames(data, 'Not Pruned To Target%'))
    }
    if (isStatsByCane) {
      setHighestBlockPotential(findHighestValueBlockNames(data, 'Canes Laid%'))
      setLowestBlockPotential(findHighestValueBlockNames(data, 'Canes Not Laid%'))
    }
  }, [data, statsBy])

  return (
    <>
      <Box display="flex" flexWrap="wrap" gap={2}>
        <Box display="flex" gap={2} flexWrap="wrap">
          <HighlightsInfoWrapper
            label="showing_stats_for"
            mainHighlightComponent={<ConditionalStatsTileContent aggregatedData={data} />}
            tooltipInfo={'pruning_page.summary.tooltip_showing_stats_for'}
          />
          <HighlightsInfoWrapper
            label="block(s)"
            mainHighlightComponent={
              <HighlightInfo value={numberFmtThousandSeparator(totalVines)} label="vines_scanned" color="info.dark" />
            }
          >
            <HighlightColumnWrapper>
              <HighlightInfo value={numberFmtThousandSeparator(totalDeadVines)} label="dead_vines" color="error.main" />
              <HighlightInfo value={numberFmtThousandSeparator(totalMissingVines)} label="missing_vines" color="error.main" />
            </HighlightColumnWrapper>

            {isStatsByVine && (
              <HighlightColumnWrapper divider>
                <HighlightInfo
                  value={maxPrunedToTarget.value + '%'}
                  label="best_pruned"
                  blockNames={maxPrunedToTarget.blockNames}
                  labelTooltip={'pruning_page.summary.tooltip_best_pruned_block'}
                />
                <HighlightInfo
                  value={maxNotPrunedToTarget.value + '%'}
                  label="worst_pruned"
                  color="error.main"
                  blockNames={maxNotPrunedToTarget.blockNames}
                  labelTooltip={'pruning_page.summary.tooltip_worst_pruned_block'}
                />
              </HighlightColumnWrapper>
            )}

            {isStatsByCane && (
              <HighlightColumnWrapper divider>
                <HighlightInfo
                  value={highestBlockPotential.value + '%'}
                  label="highest_block_potential"
                  blockNames={highestBlockPotential.blockNames}
                />
                <HighlightInfo
                  value={lowestBlockPotential.value + '%'}
                  label="lowest_block_potential"
                  color="error.main"
                  blockNames={lowestBlockPotential.blockNames}
                />
              </HighlightColumnWrapper>
            )}

            <HighlightColumnWrapper divider>
              <HighlightInfo
                value={maxProductive.value + '%'}
                label="most_productive"
                blockNames={maxProductive.blockNames}
                labelTooltip={'pruning_page.summary.tooltip_most_productive_block'}
              />
              <HighlightInfo
                value={leastProductive.value + '%'}
                label="least_productive"
                color="error.main"
                blockNames={leastProductive.blockNames}
                labelTooltip={'pruning_page.summary.tooltip_least_productive_block'}
              />
            </HighlightColumnWrapper>
          </HighlightsInfoWrapper>
          <SummaryTileWrapper
            label={isStatsByVine ? 'pruning_stats' : 'total_potential'}
            width={308}
            height={150}
            tooltipMsg={
              isStatsByVine ? 'pruning_page.summary.tooltip_pruning_stats' : 'pruning_page.summary.cane.tooltip_total_potential'
            }
          >
            <PieChart
              data={rowStatsPruningToEChartsData(data, t, statsBy)}
              tooltipFormatter={(p) => pieChartTooltipFormatter(p, statsBy)}
            />
          </SummaryTileWrapper>
        </Box>
      </Box>
      <SummaryTileWrapper
        label={isStatsByVine ? 'cane_target_stats' : 'block_potential_stats'}
        width="100%"
        height="100%"
        tooltipMsg={
          isStatsByVine ? 'pruning_page.summary.tooltip_compare_canes' : 'pruning_page.summary.cane.tooltip_block_potential_stats'
        }
        headerComponent={
          canesDataset.source.length > 1 && (
            <SortBlocksMenu
              id="sort-canes-data"
              menuItems={isStatsByVine ? canesSortOptions : blockPotentialSortOptions}
              value={activeSort}
              onClick={(val) => setActiveSort(val)}
              menuArrowPositionRight={110}
            />
          )
        }
      >
        <Box py={4}>
          <StackedHorizontalBar
            dataset={canesDataset}
            color={statsBy === 'cane' ? [palette.range.posHigh, palette.range.negHigh] : undefined}
            tooltipFormatter={(p) => tooltipFormatter(p, statsBy, palette.range)}
            yAxisCategoryLabelFormatter={yAxisCategoryLabelFormatter}
          />
        </Box>
      </SummaryTileWrapper>
      <CanePruningBudsStatsBoxplotChart data={data} dataBy={statsBy} />
    </>
  )
}

export default CanePrunedSection

const tooltipFormatter = (params: TopLevelFormatterParams, statsBy: 'cane' | 'vine', range: ColorRange) => {
  const { t } = i18next
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value } = params[0]
  const nameWithoutLastChar = name.slice(0, name.length - 1)
  const blockName = nameWithoutLastChar.split('>')[0] || nameWithoutLastChar
  let result = `<div style="margin-bottom: 6px; border-bottom: 1px solid grey; margin-bottom: 10px">
                   <div style="text-align: left; font-weight: 600; font-size: 14px">${blockName}</div>
                   
                   ${
                     statsBy === 'cane'
                       ? `<div style="margin-bottom: 4px">${t('total_potential_canes')}: <b>${Intl.NumberFormat('en-NZ').format(value.canesLaid + value.canesNotLaid)}</b></div>`
                       : `<div style="margin-bottom: 4px">${t('assumed_cane_target')}: <b>${value.assumedCaneTarget}</b></div>
                          <div style="margin-bottom: 4px">${t('total_vines')}: <b>${Intl.NumberFormat('en-NZ').format(value.totalVines)}</b></div>`
                   }
                </div>`

  if (statsBy === 'cane') {
    result += `<div>
                    <div style="margin-bottom: 4px; width: 250px; display: flex; justify-content: space-between;">
                        <span>${t('canes_laid')}</span>
                        <span><b>${Intl.NumberFormat('en-NZ').format(value.canesLaid)}</b>&nbsp;(${value['canesLaid%']}%)</span>
                    </div>
                    <div style="margin-bottom: 4px; width: 250px; display: flex; justify-content: space-between;">
                        <span>${t('canes_not_laid')}</span>
                        <span><b>${Intl.NumberFormat('en-NZ').format(value.canesNotLaid)}</b>&nbsp;(${value['canesNotLaid%']}%)</span>
                    </div>
               </div>`

    return result
  }

  const caneValues = [
    { key: '4_cane', value: value['4 Cane'], pct: value['4 Cane%'] },
    { key: '3_cane', value: value['3 Cane'], pct: value['3 Cane%'] },
    { key: '2_cane', value: value['2 Cane'], pct: value['2 Cane%'] },
    { key: '1_cane', value: value['1 Cane'], pct: value['1 Cane%'] },
  ]

  caneValues.forEach((cane) => {
    if (cane.value !== 0) {
      result += `<div style="display: flex; color: ${range.contrastText}; justify-content: space-between; gap: 2; background-color: ${colorByAssumedCaneTarget(
        value.assumedCaneTarget,
        cane.key,
        range
      )}">
           <b style="width: 120px; padding:6px 0 6px 8px">${t(cane.key)}:</b>
           <span style="padding:6px 8px 6px 8px"><b>${Intl.NumberFormat('en-NZ').format(cane.value)}</b>&nbsp;(${cane.pct}%)</span></span>
        </div>`
    }
  })
  return result
}

const yAxisCategoryLabelFormatter = (value: string) => {
  const lastChar = value[value.length - 1]
  const valueWithoutLastChar = value.slice(0, value.length - 1)
  const blockName = valueWithoutLastChar.split('>')[0] || valueWithoutLastChar
  return blockName.length > 14 ? blockName.slice(0, 14) + `.. [${lastChar}]` : blockName + ` [${lastChar}]`
}

const pieChartTooltipFormatter = (params: TopLevelFormatterParams, statsBy: 'cane' | 'vine') => {
  const { t } = i18next
  // @ts-expect-error - TS doesn't know that params is an object
  const { name, value, vines } = params.data

  return `<div>
            <div style="text-align: left; font-weight: 600; font-size: 14px; margin-bottom:10px">${name}</div>
            ${
              statsBy === 'vine'
                ? `<div style="margin-bottom: 4px">${t('vines')}: <b>${Intl.NumberFormat('en-NZ').format(vines)} (${value}%)</b></div>`
                : `<b>${Intl.NumberFormat('en-NZ').format(vines)} (${value}%)</b>`
            }
          </div>`
}
