import { Text, View } from '@react-pdf/renderer'
import { ColorRange } from '@typings/common'
import { PruningRowStatsAggregated } from '@typings/dtos/vine-row-stats'
import { colorByAssumedCaneTarget } from '@utils/utility-fns'
import { styles as s } from './utils'

type CanesBarChartProps = {
  data: PruningRowStatsAggregated
  range: ColorRange
}

const CanesBarChart = ({ data, range }: CanesBarChartProps) => {
  const caneTarget = data['Assumed Cane Target'] as 1 | 2 | 3 | 4

  return (
    <View style={{ flexDirection: 'row', marginTop: 10, gap: 2 }}>
      {caneTarget === 4 && (
        <View
          style={[
            s.bar,
            {
              width: `${data['4 Cane%']}%`,
              backgroundColor: colorByAssumedCaneTarget(caneTarget, '4_cane', range),
              color: range.contrastText,
            },
          ]}
        >
          {data['4 Cane%'] > 10 && <Text>{data['4 Cane%']}%</Text>}
        </View>
      )}
      {caneTarget >= 3 && (
        <View
          style={[
            s.bar,
            {
              width: `${data['3 Cane%']}%`,
              backgroundColor: colorByAssumedCaneTarget(caneTarget, '3_cane', range),
              color: range.contrastText,
            },
          ]}
        >
          {data['3 Cane%'] > 10 && <Text>{data['3 Cane%']}%</Text>}
        </View>
      )}
      {caneTarget >= 2 && (
        <View
          style={[
            s.bar,
            {
              width: `${data['2 Cane%']}%`,
              backgroundColor: colorByAssumedCaneTarget(caneTarget, '2_cane', range),
              color: range.contrastText,
            },
          ]}
        >
          {data['2 Cane%'] > 10 && <Text>{data['2 Cane%']}%</Text>}
        </View>
      )}
      <View
        style={[
          s.bar,
          {
            width: `${data['1 Cane%']}%`,
            backgroundColor: colorByAssumedCaneTarget(caneTarget, '1_cane', range),
            color: range.contrastText,
          },
        ]}
      >
        {data['1 Cane%'] > 10 && <Text>{data['1 Cane%']}%</Text>}
      </View>
    </View>
  )
}

export default CanesBarChart
