import { SxProps, Theme } from '@mui/material/styles'

export const ABSOLUTE_CENTER_SX: SxProps<Theme> = {
  position: 'absolute',
  maxHeight: '100vh',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const SNACKBAR_ICON_SX: SxProps<Theme> = {
  fontSize: '20px',
  marginInlineEnd: '8px',
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentcolor',
  flexShrink: 0,
}
