import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer'
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol'

export const rowsLayer = new FeatureLayer({
  id: 'rows-layer',
  title: 'Rows Layer',
  source: [],
  fields: [{ name: 'id', type: 'integer' }],
  objectIdField: 'id',
  geometryType: 'polyline',
  outFields: ['id'],
  renderer: new SimpleRenderer({
    symbol: new SimpleLineSymbol({ color: 'rgba(147, 237, 196, 0.6)', width: 2 }),
  }),
})
