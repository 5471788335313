import { Image, Page, Text, View } from '@react-pdf/renderer'
import { ColorRange } from '@typings/common'
import { PruningRowStatsAggregated, RowStatsPruningDto } from '@typings/dtos/vine-row-stats'
import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import CaneCountTiles from './CaneCountTiles'
import CanesBarChart from './CanesBarChart'
import TableHead from './TableHead'
import TableRow from './TableRow'
import { styles as s } from './utils'

export type BlockDetailsPageProps = {
  caneData: PruningRowStatsAggregated
  rowStats: RowStatsPruningDto[]
  range: ColorRange
}

const BlockDetailsPage = ({ caneData, rowStats, range }: BlockDetailsPageProps) => {
  const [bestPrunedRows, setBestPrunedRows] = useState<RowStatsPruningDto[]>([])
  const [worstPrunedRows, setWorstPrunedRows] = useState<RowStatsPruningDto[]>([])

  const SectionTitle = ({ title }: { title: string }) => (
    <View style={{ margin: '20px 0px 6px 0' }}>
      <Text style={{ color: '#475569', fontSize: 11 }}>{title}</Text>
    </View>
  )

  const VinesInfo = ({ title, value, asterisk }: { title: string; value: number; asterisk?: boolean }) => (
    <View style={{ border: '1px solid #F5F5F5', flex: 1 }}>
      <View style={{ backgroundColor: '#F5F5F5', position: 'relative' }}>
        <Text style={{ margin: 4, fontSize: 10 }}>{title}</Text>
        {asterisk && <Text style={{ position: 'absolute', top: 0, right: '2px', fontSize: 14, color: '#F97316' }}>*</Text>}
      </View>
      <Text style={{ margin: '8px 4px', color: '#475569', fontSize: 14 }}>{Intl.NumberFormat('en-NZ').format(value)}</Text>
    </View>
  )

  const rowNumbers = rowStats.map((row) => row.rowNumber).sort((a, b) => a - b)

  useEffect(() => {
    if (!rowStats.length) return

    const tenBestAndWorstPrunedRows = () => {
      if (!rowStats.length) return { best: [], worst: [] }

      const sortedCaneData = rowStats.slice().sort((a, b) => {
        const aValue = a.stats['pruned To Target%'] !== undefined ? a.stats['pruned To Target%'] : Number.MAX_VALUE
        const bValue = b.stats['pruned To Target%'] !== undefined ? b.stats['pruned To Target%'] : Number.MAX_VALUE
        return aValue - bValue
      })

      if (sortedCaneData.length < 20) {
        const sliceRange = Math.floor(sortedCaneData.length / 2)
        if (sortedCaneData.length % 2 === 0) {
          return { best: sortedCaneData.slice(-sliceRange).reverse(), worst: sortedCaneData.slice(0, sliceRange) }
        }
        return { best: sortedCaneData.slice(-sliceRange - 1).reverse(), worst: sortedCaneData.slice(0, sliceRange) }
      }

      return { best: sortedCaneData.slice(-10).reverse(), worst: sortedCaneData.slice(0, 10) }
    }

    const { best, worst } = tenBestAndWorstPrunedRows()

    setBestPrunedRows(best)
    setWorstPrunedRows(worst)
  }, [caneData])

  const dateRange = () => {
    const startDate = format(parseISO(caneData['Start Date']), 'dd MMM yyyy')
    const endDate = format(parseISO(caneData['End Date']), 'dd MMM yyyy')

    if (startDate === endDate) return startDate
    return `${startDate} \u00A0\u2014\u00A0 ${endDate}`
  }

  return (
    <Page size="A4" break>
      <View style={s.layout}>
        {/* HEADER */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Text id={caneData['Block Id'].toString()}>{caneData['Block Name']}</Text>
          <View style={[s.dateWrapper, { fontSize: 10 }]}>
            <Text style={{ margin: 0, padding: 0 }}>{dateRange()}</Text>
          </View>
        </View>
        <View style={s.divider} />

        {/* VINES SCANNED */}
        <SectionTitle title="VINES SCANNED" />
        <View>
          <View style={{ flexDirection: 'row' }}>
            <VinesInfo title="Normal" value={caneData['Normal Vines']} />
            <VinesInfo title="Young" value={caneData['Young Vines']} />
            <VinesInfo title="Missing" value={caneData['Missing Vines']} />
            <VinesInfo title="Dead / Zero Cane" value={caneData['Dead Vines']} />
            <VinesInfo asterisk title="Buds Per Vine" value={caneData['Buds Per Vine Mean']} />
            <VinesInfo asterisk title="Buds Per Cane" value={caneData['Buds Per Cane Mean']} />
          </View>
        </View>

        {/* CANE TARGET */}
        <View style={{ margin: '32px 0px 6px 0', flexDirection: 'row', alignItems: 'flex-end', gap: 6, paddingBottom: 2 }}>
          <Text style={{ color: '#475569', fontSize: 12 }}>CANE TARGET</Text>
          <View
            style={{
              backgroundColor: '#475569',
              color: '#FFF',
              fontSize: 12,
              height: 16,
              width: 16,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>{caneData['Assumed Cane Target']}</Text>
          </View>
        </View>
        <CaneCountTiles data={caneData} range={range} />
        <CanesBarChart data={caneData} range={range} />

        {/* BEST & WORST PRUNED */}
        <View style={{ flexDirection: 'row', gap: 16, marginTop: 30 }}>
          <View style={{ flex: 1 }}>
            <SectionTitle title="BEST PRUNED ROWS" />
            <TableHead assumedCaneTarget={caneData['Assumed Cane Target'] as 1 | 2 | 3 | 4} range={range} />
            {bestPrunedRows.map((row, i) => (
              <TableRow key={row.rowNumber} row={row} i={i} assumedCaneTarget={caneData['Assumed Cane Target']} />
            ))}
          </View>
          <View style={{ flex: 1 }}>
            <SectionTitle title="WORST PRUNED ROWS" />
            <TableHead assumedCaneTarget={caneData['Assumed Cane Target'] as 1 | 2 | 3 | 4} range={range} />
            {worstPrunedRows.map((row, i) => (
              <TableRow key={row.rowNumber} row={row} i={i} assumedCaneTarget={caneData['Assumed Cane Target']} />
            ))}
          </View>
        </View>

        {/* ROWS SCANNED */}
        <View style={{ marginTop: 'auto' }}>
          <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Oblique', color: '#1E293B', fontWeight: 200 }}>
            Rows Scanned ( {Intl.NumberFormat('en-NZ').format(rowNumbers.length)} ):
          </Text>
          <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Oblique', color: '#475569' }}>{rowNumbers.join(', ')}</Text>
          <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Oblique', color: '#1E293B', fontWeight: 200, marginTop: 8 }}>
            Vines Excluded:
          </Text>
          <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Oblique', color: '#475569' }}>
            {`${caneData['Unknown Vines'] + ' (Unknown), '}`} {`${caneData['Bad Quality Vines'] + ' (Bad Quality)'}`}
          </Text>
          <View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 8, alignItems: 'center', gap: 4 }}>
              <View style={{ width: 5, height: 5, borderRadius: 4, backgroundColor: '#F97316', marginRight: '2px' }} />
              <Text style={{ fontSize: 10, color: '#F97316' }}>mean</Text>
            </View>
            <View
              style={{ display: 'flex', flexDirection: 'row', marginTop: -4, alignItems: 'center', gap: 4, color: '#F97316' }}
            >
              <Text style={{ fontSize: 17, marginTop: 8 }}>*</Text>
              <Text style={{ fontSize: 10 }}>mean, calculated as weighted average based on the number of vines per row</Text>
            </View>
          </View>
        </View>
      </View>

      {/* Footer */}
      <Text style={s.pageNumber} render={({ pageNumber }) => `${pageNumber}`} fixed />
      <Image src="/logos/head-logo.png" style={s.footerLogo} />
    </Page>
  )
}

export default BlockDetailsPage
