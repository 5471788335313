import { UserApi } from '@services/api'
import useAuthStore from '@stores/auth'
import useVineyardStore from '@stores/vineyard'
import { useMutation, useQuery } from '@tanstack/react-query'
import { BlockDto, Variety } from '@typings/dtos/block'
import { VineyardDto } from '@typings/dtos/vineyard'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useHandleFilterFavourites(
  setActiveFilter: Dispatch<SetStateAction<string>>,
  setToggleScanRecency: Dispatch<SetStateAction<string>>
) {
  const handleAxiosError = useHandleAxiosError()
  const [userSettings, setUserSettings] = useAuthStore((s) => [s.userSettings, s.setUserSettings])
  const [blocksAsMap, setSelectedBlocksLocal, vineyards, setSelectedVineyardsLocal, varieties, setSelectedVarietiesLocal] =
    useVineyardStore((s) => [
      s.blocksAsMap,
      s.setSelectedBlocksLocal,
      s.vineyards,
      s.setSelectedVineyardsLocal,
      s.varieties,
      s.setSelectedVarietiesLocal,
    ])

  const { data } = useQuery({
    queryKey: ['userSettings'],
    queryFn: UserApi.getUserSettings,
    enabled: !userSettings,
  })

  useEffect(() => {
    if (data) setUserSettings(data)
  }, [data])

  const { mutate } = useMutation({
    mutationKey: ['userSettings'],
    mutationFn: UserApi.postUserSettings,
    onSuccess: (data) => setUserSettings(data),
    onError: (error) => handleAxiosError(error),
  })

  if (!userSettings) return { favourites: [], mutate, handleFavOnDelete: () => {}, handleFavChipOnClick: () => {} }

  const handleFavOnDelete = (favName: string) => {
    setSelectedBlocksLocal([])
    setSelectedVineyardsLocal([])
    setSelectedVarietiesLocal([])
    setActiveFilter('')
    const newFavourites = userSettings.favouriteViews.filter((fav) => fav.name !== favName)
    mutate({ ...userSettings, favouriteViews: newFavourites })
  }

  const handleFavChipOnClick = (favName: string) => {
    const selectedFav = userSettings.favouriteViews.find((fav) => fav.name === favName)
    if (!selectedFav) return

    const { _blockIds, vineyardIds, varietyIds } = selectedFav.blocks.reduce<{
      _blockIds: number[]
      vineyardIds: number[]
      varietyIds: number[]
    }>(
      (acc, block) => {
        if (!acc._blockIds.includes(block.id)) acc._blockIds.push(block.id)
        if (!acc.vineyardIds.includes(block.vineyardId)) acc.vineyardIds.push(block.vineyardId)
        if (!acc.varietyIds.includes(block.varietyId)) acc.varietyIds.push(block.varietyId)
        return acc
      },
      { _blockIds: [], vineyardIds: [], varietyIds: [] }
    )

    // Create Maps for faster lookup
    const vineyardMap = new Map(vineyards.map((vineyard) => [vineyard.id, vineyard]))
    const varietyMap = new Map(varieties.map((variety) => [variety.id, variety]))

    // Filter the vineyards and varieties using Maps
    const _vineyards = vineyardIds.map((id) => vineyardMap.get(id)).filter(Boolean) as VineyardDto[]
    const _varieties = varietyIds.map((id) => varietyMap.get(id)).filter(Boolean) as Variety[]
    const _blocks = _blockIds.map((id) => blocksAsMap.get(id)).filter(Boolean) as BlockDto[]

    setSelectedBlocksLocal(_blocks)
    setSelectedVineyardsLocal(_vineyards)
    setSelectedVarietiesLocal(_varieties)
    setActiveFilter(favName)
    setToggleScanRecency('')
  }

  const { favouriteViews: favourites } = userSettings

  return { favourites, mutate, handleFavOnDelete, handleFavChipOnClick }
}
