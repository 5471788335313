import { Icon, type IconifyIcon } from '@iconify/react'

import Box, { BoxProps } from '@mui/material/Box'
import { forwardRef } from 'react'

type IconifyProps = BoxProps & {
  icon: IconifyIcon | string
}

const Iconify = forwardRef<SVGElement, IconifyProps>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
))

export default Iconify
