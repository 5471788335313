import { GridColDef } from '@mui/x-data-grid-pro'
import { BlockDto, PruningStyleUnion } from '@typings/dtos/block'
import { createFormatter } from '@utils/utility-fns'
import { TFunction } from 'i18next'

export const landCols = (blocksAsMap: Map<number, BlockDto>, pruningStyle: PruningStyleUnion, t: TFunction): GridColDef[] => {
  const sharedCols: GridColDef[] = [
    { field: 'scanDate', headerName: 'Date', valueGetter: (value) => new Date(value), type: 'date', width: 100 },
    { field: 'blockId', headerName: t('block'), width: 140, valueGetter: (value) => blocksAsMap.get(value)?.name },
    { field: 'rowNumber', headerName: t('row'), width: 70, type: 'number' },
  ]

  const postsCols: GridColDef[] = [
    {
      field: 'woodenPosts',
      headerName: t('wooden_posts'),
      width: 130,
      valueGetter: (_, row) => row.stats['wooden Posts'],
      valueFormatter: createFormatter(),
      type: 'number',
    },
    {
      field: 'metalPosts',
      headerName: t('metal_posts'),
      width: 130,
      valueGetter: (_, row) => row.stats['metal Posts'],
      valueFormatter: createFormatter(),

      type: 'number',
    },
    {
      field: 'missingPosts',
      headerName: t('missing_posts'),
      width: 150,
      valueGetter: (_, row) => row.stats['missing Posts'],
      valueFormatter: createFormatter(),
      type: 'number',
    },
    {
      field: 'unknownPosts',
      headerName: t('unknown_posts'),
      width: 140,
      valueGetter: (_, row) => row.stats['unknown Posts'],
      valueFormatter: createFormatter(),
      type: 'number',
    },
  ]

  const bowCols: GridColDef[] = [
    {
      field: 'youngVines',
      headerName: t('young_vines'),
      width: 120,
      valueGetter: (_, row) => row.stats['young Vines'],
      type: 'number',
    },
    {
      field: 'missingVines',
      headerName: t('missing_vines'),
      width: 150,
      valueGetter: (_, row) => row.stats['missing Vines'],
      type: 'number',
    },
    {
      field: 'normalVines',
      headerName: t('normal_vines'),
      width: 130,
      valueGetter: (_, row) => row.stats['normal Vines'],
      type: 'number',
    },
    {
      field: 'unknownVines',
      headerName: t('unknown_vines'),
      width: 140,
      valueGetter: (_, row) => row.stats['unknown Vines'],
      type: 'number',
    },
    {
      field: 'lowQualityVines',
      headerName: t('low_quality_vines'),
      width: 180,
      valueGetter: (_, row) => row.stats['bad Quality Vines'],
      type: 'number',
    },
  ]

  const spurCols: GridColDef[] = [
    {
      field: 'missingCordon1',
      headerName: t('1_missing_cordon'),
      width: 150,
      valueGetter: (_, row) => row.stats['missing Cordons 1'],
      type: 'number',
    },
    {
      field: 'missingCordon2',
      headerName: t('2_missing_cordons'),
      width: 150,
      valueGetter: (_, row) => row.stats['missing Cordons 2'],
      type: 'number',
    },
  ]

  const caneCols: GridColDef[] = [
    {
      field: 'deadVines',
      headerName: `0 ${t('canes')}/${t('dead')}`,
      width: 130,
      valueGetter: (_, row) => row.stats['dead Vines'],
      type: 'number',
    },
  ]

  const colsByPruningStyle: Record<PruningStyleUnion, GridColDef[]> = {
    Bow: [...sharedCols, ...bowCols],
    Spur: [...sharedCols, ...bowCols, ...spurCols],
    Cane: [...sharedCols, ...bowCols, ...caneCols, ...postsCols],
  }

  return colsByPruningStyle[pruningStyle]
}
