import Iconify from '@components/data-display/Iconify'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import TextField, { TextFieldProps } from './TextField'

type PasswordTextFieldProps = TextFieldProps

const PasswordTextField = (props: PasswordTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setShowPassword((prev) => !prev)}>
            {showPassword ? (
              <Iconify icon="material-symbols:visibility" />
            ) : (
              <Iconify icon="material-symbols:visibility-off" />
            )}
          </IconButton>
        ),
      }}
      {...props}
    />
  )
}

export default PasswordTextField
