import { IOption, LandingPageByCountryAndDate } from '@typings/common'
import { ToggleCompareBlockEnum } from '@typings/component.enums'
import { BlockPruningStyleEnum } from '@typings/dtos/block'

export const toggleCompareBlockOptions: [IOption<string>, IOption<string>] = [
  { label: 'Pruning', value: ToggleCompareBlockEnum.Pruning },
  { label: 'Canes', value: ToggleCompareBlockEnum.Canes },
]

export const pruningSortOptions: IOption<string>[] = [
  { label: 'Block Name', value: 'name' },
  { label: 'Pruned to Target', value: 'pruned-to-target' },
]

export const canesSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'pruned_to_target', value: 'itemA' },
  { label: '4_cane', value: '4 Cane' },
  { label: '3_cane', value: '3 Cane' },
  { label: '2_cane', value: '2 Cane' },
  { label: '1_cane', value: '1 Cane' },
]

export const blockPotentialSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'canes_laid', value: 'itemA' },
]

export const LandCane_VineStatsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'missing_vines', value: 'Missing Vines%' },
  { label: 'dead_vines', value: 'Dead Vines%' },
  { label: 'young_vines', value: 'Young Vines%' },
]

export const LandCane_PostsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'wooden_posts', value: 'wooden' },
  { label: 'metal_posts', value: 'metal' },
  { label: 'missing_posts', value: 'missing' },
  { label: 'unknown_posts', value: 'unknown' },
]

export const LandSpur_VineStatsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'missing_vines', value: 'Missing Vines%' },
  { label: 'young_vines', value: 'Young Vines%' },
  { label: '1_missing_cordon', value: '1 Missing Cordon%' },
  { label: '2_missing_cordons', value: '2 Missing Cordons%' },
]

export const LandBow_VineStatsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'missing_vines', value: 'Missing Vines%' },
  { label: 'young_vines', value: 'Young Vines%' },
]

export const expectedVineStatsSortMap = new Map([
  ['name-asc', 'name'],
  ['name-desc', 'name'],
  ['missing-vines-asc', 'Missing Vines%'],
  ['missing-vines-desc', 'Missing Vines%'],
  ['dead-vines-asc', 'Dead Vines%'],
  ['dead-vines-desc', 'Dead Vines%'],
  ['young-vines-asc', 'Young Vines%'],
  ['young-vines-desc', 'Young Vines%'],
])

export const labelByTrainingSystem: { [key in BlockPruningStyleEnum]: string } = {
  [BlockPruningStyleEnum.HighBow]: 'BOW',
  [BlockPruningStyleEnum.LowBow]: 'BOW',
  [BlockPruningStyleEnum.Spur]: 'SPUR',
  [BlockPruningStyleEnum.VSP]: 'CANE',
  [BlockPruningStyleEnum.Sylvos]: '',
}

export const budsPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'blockName' },
  { label: 'mean', value: 'Bow Buds Per Vine Mean' },
]

export const Pruning_BudsSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'LQ' },
  { label: 'mean', value: 'Mean' },
  { label: 'upper_quartile', value: 'UQ' },
]

export const PruningSpur_BudsPerSpurPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'Buds Per Spur Per Vine LQ' },
  { label: 'mean', value: 'Buds Per Spur Per Vine Mean' },
  { label: 'upper_quartile', value: 'Buds Per Spur Per Vine UQ' },
]

export const PruningSpur_SpursPerVineSortOptions: IOption<string>[] = [
  { label: 'block_name', value: 'Block Name' },
  { label: 'lower_quartile', value: 'Spurs Per Vine LQ' },
  { label: 'mean', value: 'Spurs Per Vine Mean' },
  { label: 'upper_quartile', value: 'Spurs Per Vine UQ' },
]

export const seasonsByHemisphere: Record<'north' | 'south', LandingPageByCountryAndDate[]> = {
  north: [
    { season: 'in-season', landingPage: 'health', startMonth: 3, endMonth: 10 },
    { season: 'off-season', landingPage: 'pruning', startMonth: 11, endMonth: 2 },
  ],
  south: [
    { season: 'in-season', landingPage: 'health', startMonth: 10, endMonth: 4 },
    { season: 'off-season', landingPage: 'pruning', startMonth: 5, endMonth: 9 },
  ],
}
