import Box from '@mui/material/Box'
import { MouseEventHandler, ReactNode } from 'react'

type ScrollingInputWrapperProps = {
  id: string
  label: ReactNode
  children: ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
  labelColor?: string
  borderColor?: string
  loading?: boolean
}

const ScrollingInputWrapper = ({
  id,
  label,
  children,
  onClick,
  labelColor = 'grey.700',
  borderColor = 'grey.400',
  loading,
}: ScrollingInputWrapperProps) => {
  return (
    <div>
      <Box htmlFor={id} component="label" fontSize={12} fontWeight={600} color={labelColor} textTransform="uppercase">
        {label}
      </Box>
      <Box
        id={id}
        sx={{
          position: 'relative',
          bgcolor: 'white',
          border: '1px solid',
          borderColor: borderColor,
          borderRadius: 1,
          display: 'flex',
          flexWrap: 'wrap',
          p: 0.5,
          height: 75,
          overflowY: 'auto',
          overflowX: 'hidden',
          width: 292,
          cursor: loading ? 'wait' : 'pointer',
          ':hover': { borderColor: 'primary.dark' },
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': { width: '0.4em' },
          '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
          '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
        }}
        onClick={onClick}
      >
        {children}
      </Box>
    </div>
  )
}

export default ScrollingInputWrapper
