import { BarChart, BoxplotChart, PieChart } from 'echarts/charts'
import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components'
import type { ECharts, EChartsCoreOption, SetOptionOpts } from 'echarts/core'
import { getInstanceByDom, init, use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { useEffect, useRef } from 'react'

// Register the required components
use([
  LegendComponent,
  PieChart,
  BarChart,
  BoxplotChart,
  DatasetComponent,
  GridComponent,
  TooltipComponent,
  ToolboxComponent, // A group of utility tools, which includes export, data view, dynamic type switching, data area zooming, and reset.
  DataZoomComponent, // Used in Line Graph Charts
  CanvasRenderer, // If you only need to use the canvas rendering mode, the bundle will not include the SVGRenderer module, which is not needed.
])

// Combine an Option type with only required components and charts via ComposeOption
// export type EChartsOption = ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption | ScatterSeriesOption
// >

export type EChartsProps = {
  option: EChartsCoreOption
  settings?: SetOptionOpts
  loading?: boolean
  height?: number | string
}

const EChartsWrapper = ({ option, settings, loading, height }: EChartsProps) => {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined
    if (chartRef.current !== null) {
      chart = init(chartRef.current)
    }

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    // Return cleanup function
    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      if (chart) {
        chart.showLoading()
        chart.setOption(option, { notMerge: true })
        chart.hideLoading()
      }
    }
  }, [option, settings])
  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart?.showLoading() : chart?.hideLoading()
    }
  }, [loading])

  return <div ref={chartRef} style={{ width: '100%', height: height }} />
}

export default EChartsWrapper
