import { Helmet } from 'react-helmet-async'

type HelmetWrapperProps = {
  children: React.ReactNode
  title: string
}

const HelmetWrapper = ({ children, title }: HelmetWrapperProps) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  )
}

export default HelmetWrapper
