import { BlockApi } from '@services/api'
import useVineyardStore from '@stores/vineyard'
import { useQuery } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useHandleAxiosError } from './useHandleAxiosError'

export function useGetBlocksAndScannedApis() {
  const [setBlocks, setBlocksGroupByScanDate, setBlockIdsByPruningStyle] = useVineyardStore(
    useShallow((s) => [s.setBlocks, s.setBlocksGroupByScanDate, s.setBlockIdsByPruningStyle])
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const handleAxiosError = useHandleAxiosError()

  /* GET Blocks Scanned */
  const { data: blockScannedData, isFetching: isFetchingScanned } = useQuery({
    queryKey: ['blocksScanned'],
    queryFn: () => BlockApi.getBlocksScanned(),
    initialData: [],
  })

  /* GET Blocks */
  const {
    data: blocksData,
    isLoading: isLoadingBlocks,
    error: blocksError,
    isError: blocksIsError,
    isFetching: isFetchingBlocks,
    // refetch: refetchBlocks,
  } = useQuery({
    queryKey: ['blocks'],
    queryFn: () => BlockApi.getBlocks(),
    initialData: [],
    select: (data) =>
      data.map((block) => {
        const scanDate = blockScannedData.find((b) => b.blockId === block.id)?.scanDate || ''
        return { ...block, scanDate }
      }),
  })

  /* Wait for blocks scanned data to be fetched before start fetching blocks */
  // useEffect(
  //   () => {
  //     if (blockScannedData.length === 0 || blockScannedData === undefined) {
  //       return
  //     }
  //     refetchBlocks()
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [blockScannedData]
  // )

  /* Add scanned dates in blocks data + create/set varieties */
  useEffect(() => {
    if (blocksData && blockScannedData) {
      const blocksWithScannedDates = blocksData.map((block) => {
        const scanDate = blockScannedData.find((b) => b.blockId === block.id)?.scanDate || ''
        return { ...block, scanDate }
      })
      setBlocks(blocksWithScannedDates)

      /* Grouping blocks by scanned date, so it can be used inside block scan timeline */
      const updatedGroupedDataArray: { scanDate: string; blockIds: number[] }[] = []
      blockScannedData.forEach((item) => {
        const formattedDate = format(parseISO(item.scanDate), 'yyyy-MM-dd')
        const existingEntry = updatedGroupedDataArray.find((entry) => entry.scanDate === formattedDate)

        if (existingEntry) {
          const uniqueBlocks = new Set(existingEntry.blockIds)
          uniqueBlocks.add(item.blockId)

          existingEntry.blockIds = Array.from(uniqueBlocks)
        } else {
          updatedGroupedDataArray.push({ scanDate: formattedDate, blockIds: [item.blockId] })
        }
      })
      setBlocksGroupByScanDate(updatedGroupedDataArray)

      /* Set block ids by pruning style */
      setBlockIdsByPruningStyle(blocksData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockScannedData, blocksData])

  /* Show Snackbar while fetching blocks */
  useEffect(() => {
    if (isFetchingBlocks) {
      enqueueSnackbar(`Fetching blocks...`, {
        variant: 'info',
        key: 'fetching-blocks',
        autoHideDuration: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    } else {
      closeSnackbar('fetching-blocks')
    }

    if (isFetchingScanned) {
      enqueueSnackbar(`Fetching scan timeline...`, {
        variant: 'info',
        key: 'fetching-scanned-blocks',
        autoHideDuration: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    } else {
      closeSnackbar('fetching-scanned-blocks')
    }
  }, [isFetchingScanned, isFetchingBlocks, enqueueSnackbar, closeSnackbar])

  useEffect(() => {
    if (blocksIsError) handleAxiosError(blocksError)
  }, [blocksError, blocksIsError, handleAxiosError, isFetchingScanned])

  return { isLoadingBlocks, isFetchingBlocks }
}
