import { useTranslate } from '@hooks/useLocales'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type DialogRemoveFavouriteProps = {
  open: boolean
  onClose: VoidFunction
  onConfirm: VoidFunction
  favouriteName: string
}

const DialogRemoveFavourite = ({ open, onClose, onConfirm, favouriteName }: DialogRemoveFavouriteProps) => {
  const { t } = useTranslate()

  return (
    <Dialog open={open} onClose={onClose} disableRestoreFocus>
      <DialogTitle color="grey.800">{t('delete_fav_confirmation')}</DialogTitle>
      <DialogContent sx={{ width: 350 }}>
        <DialogContentText>{favouriteName}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: 'grey.800' }} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button sx={{ color: 'primary.dark' }} onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogRemoveFavourite
